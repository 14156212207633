import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataService {

  private messageSource = new BehaviorSubject('');
  TicketText = this.messageSource.asObservable();

  private outGoingSource = new BehaviorSubject('');
  outGoing = this.outGoingSource.asObservable();


  private viewTicketSource = new BehaviorSubject('');
  viewTicketDetails = this.viewTicketSource.asObservable();

  private openTktSource = new BehaviorSubject('');
  OpenTicketModal = this.openTktSource.asObservable();

  constructor() { }

  ticketSearch(message: string) {
    this.messageSource.next(message)
  }

  outgoingDone(message: string) {
    this.outGoingSource.next(message)
  }
  viewTicketDetail(message: string) {
    this.viewTicketSource.next(message)
  }
  openCreateTicketModal(value) {
    console.log("Create data service")
    this.openTktSource.next(value)
  }

}