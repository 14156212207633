<p-dialog [visible]="modalDialog['visible']"  
    modal="true" 
    resizable="false" 
    draggable="false"
    closeOnEscape="false"
    [showHeader]="false"
    [closable]="false"
    styleClass="messageDialog">
    <div class="p-grid" style="padding: 5%;">
        <div *ngIf="modalDialog['imgSrc']" class="p-col-12">
            <img class="message-image" [src]="modalDialog['imgSrc']" alt="Success">
        </div>
        <div class="p-col-12">
            <h4 class="bold">{{modalDialog.message || ''}}</h4>
        </div>
        <div class="p-col-12 action-btn">
            <button pButton type="button" label="View Ticket" (click)="viewTicketModal()" ></button>
            <button pButton type="button" class="ui-button-danger" label="CLOSE" (click)="closeModal()" ></button>
        </div>
    </div>


</p-dialog>
