
<!-- <button (click)="op.toggle($event)" style="margin-left: 10px;" pButton type="button" icon="pi pi-chevron-down" label="Range"></button> -->
<p-button styleClass="ui-button-secondary date-btn"  (click)="op.toggle($event)" icon="pi pi-calendar"  iconPos="left" [label]="getLabel()"></p-button>
<p-overlayPanel styleClass="date-panel" #op>
    <ng-template pTemplate style="height: 100px;">
        <div class="p-grid">
            <div class="p-col left-box">
                <p-listbox styleClass="range-box" [options]="ranges" (onChange)="rangeChange($event.value)" [(ngModel)]="selectedRange" optionLabel="name"></p-listbox>
                <div>
                    <button class="ui-button-secondary" style="margin-left: 10px;" (click)="op.hide($event)" pButton type="button" label="Cancel"></button>
                    <button style="margin-left: 3px;" (click)="applyDate(); op.hide($event)" pButton type="button" label="Apply"></button>
                </div>
            </div>
            <div class="p-col">
                <p-calendar styleClass="date-box" [inline]="true" [numberOfMonths]="2" [showIcon]="true" [(ngModel)]="selectedDates" selectionMode="range" [readonlyInput]="true"></p-calendar>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>