import { Component, OnInit,ViewEncapsulation,Input } from '@angular/core';

@Component({
  selector: 'vendor-layout',
  templateUrl: './vendor-layout.component.html',
  styleUrls: ['./vendor-layout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VendorLayoutComponent implements OnInit {

  @Input() cardDetails: any;

  constructor() { }

  ngOnInit(): void {
  }

}
