<div class="header basic-bg-1 justify-space-between">
  <span class="left-align">
    <!-- <i class="pi pi-bars menu-bar" ></i> -->
    <a (click)="onClickMenuBar()"><i class="fas fa-bars menu-bar"></i></a>
    <a href="" class="logo bold">
      <!-- HELPDESK <span class="highlight-1">CMS</span> -->
      <img [src]="headerLogo" [alt]="altName">
    </a>

    <!-- <i class="pi pi-search menu-bar"></i> -->

    <input type="text" pInputText placeholder="Search by Ticket" (keydown.enter)="SearchTicket()"
      [(ngModel)]="ticketName" class="search-input" /> &nbsp;
    <a (click)="SearchTicket()" class="search-btn"><i class="fas fa-search search-bar"></i></a>
    <button *ngIf="showCreateNewTicketButton" pButton type="button" icon="fas fa-pencil-alt" iconPos="right"
      class="ticket-button" label="Create New Ticket" (click)="createTicket()"></button>
    <!-- <span>
          <button pButton type="button" icon="far fa-calendar" iconPos="right" class="ticket-button"
            label="Calendar" (click)="openCalendar()"></button>
        </span> -->
  </span>
  <!-- <div class="ticketDetail" *ngIf="isViewTicket">
    <span class="p-col-4 justify-space-between" style="padding-top: 0; font-size: 0.8rem">
      <span *ngIf="ticketDetail['ticketId']" class="p-col-1">
        <i class="pi pi-tags"></i> {{ ticketDetail["ticketId"] || "" }}
      </span>
      <span style="display: flex; align-items: center; white-space: nowrap">
        Status - &nbsp;
        <div *ngIf="ticketDetail['currentStatus']" class="custom-chip" [ngStyle]="{
            backgroundColor:
              ticketDetail['currentStatus']['status']['colorCode']
          }">
          {{ ticketDetail["currentStatus"]["status"]["status"] }}
        </div>
      </span>
      <span>
        <i class="pi pi-clock"></i> &nbsp;{{
        ticketDetail["dueDate"] | date: "EEE, dd MMM yyyy h:mm a"
        }}
      </span>
    </span>
  </div> -->
  <!-- <span class="" style="margin-top:10px" *ngIf="isShowIframe">
    <iframe [src]="iframeUrl" *ngIf="isLogged" width="345px" height="28px" allowtransparency="true"
      style="background:#22242a;" id="vicidialIframeHeader" frameborder="0"></iframe>
  </span> -->
  <span class="" style="width:20%" *ngIf="callModel == false && inCall">
    <a class="logout incoming-call" (click)="controlModal(true)">{{timer}}</a>
  </span>
  <span class="" style="width:20%" *ngIf="callDispoModel == false && callEnded">
    <a class="logout incoming-call" (click)="controlModalDispo(true)"> {{timer}}</a>
  </span>
  <span class="right-align" style="width:20%">
    <span (click)="_logout()"><i class="fas fa-sign-out-alt" style="font-size: 21px;cursor: pointer;"></i></span>
    <!-- <a class="logout" (click)="_logout()">LOGOUT</a> -->
    <!-- <button pButton type="button" label="LOGOUT" class="ui-button-rounded ui-button-secondary p-btn-sm" (click)="logout()" ></button> -->
  </span>
</div>

<!-- Calendar Iframe fetched from calendar component -->
<common-dialog [dialogConfig]="calendarModalDialogue" (closeModal)="controlModalDispo(false)">
  <app-calendar></app-calendar>
</common-dialog>




<!-- Call Dialogue -->
<common-dialog [dialogConfig]="incommingCallDialog" (closeModal)="modalClosedCall()">
  <div class="cal-hangup-reason">
    <panel [panelConfig]="viewPanel2">
      <div panelHeader>
        <span>Incoming Call <span (click)="controlModal(false)" class="pushToBack"> Hide </span> </span>
      </div>
      <div panelBody>
        <div class="row">
          <div class="incoming-call-main">
            <div class="call-transfer-div" *ngIf="is3WayCall">
              <span class="trans-type-text"> TRANSFER TYPE : {{transferType}}</span>
              <span class="right-number-to-call">{{callToNumber}}</span>
              <br>
            </div>

            <div class="mobile-number-section">
              <span class="sub-head-incoming">Phone Number:<span *ngIf="isCallDisconnected"
                  class="call-disconnected">Call Disconnected </span> </span> <br>

              <span class="mobile-num">{{incomingPhoneNumber}} </span>
            </div>


            <div class="timer-section">
              <span class="sub-head-incoming">Call time: </span> <br>

              <span class="mobile-num" [ngClass]="{'isCallDisconnectedClass': isCallDisconnected}">{{timer}}</span>
            </div>
            <div>
              <br>
              <input type="text" pInputText placeholder="Search ticket" style="width:74%; margin-right:1px;"
                [(ngModel)]="ticketSearchCall" class="search-input" />
              <!-- &nbsp; -->
              <button pButton type="button" label="Search" style="width:24%;" (click)="searchTicketForCall()"
                class="ui-button"></button>
              <br>
            </div>
            <div class="noTicketFound" *ngIf="isNoTicketFound">

              <span class="sub-head-incoming"> No Ticket found </span>
            </div>
            <div class="ticketList" *ngIf="ticketList && !showSearch">
              <br>
              <div class="border-ticket-list">

                <div class="main-ticket-list" *ngFor="let ticket of ticketList">
                  <div class="customer-details">
                    <span class="cus-name">
                      {{ticket.customerName}}
                    </span> <br>
                    <div class="ticket-info">
                      <div class="cus-name-details">
                        {{ticket.service_type.name}} - {{ticket.ticketId}}
                        <br>
                        {{ticket.dueDate | date:'MMM d, y, h:mm a'}}
                        <span class="right-view-button">
                          <button pButton type="button" label="View" (click)="viewTicket(ticket.id, ticket.ticketId)"
                            class="ui-button"></button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <br>
          </div>

        </div>
        <div class="bottom-button">
          <button pButton type="button" label="Transfer" (click)="openCallTransferDialog()"
            *ngIf="!isCallDisconnected && !is3WayCall" class="ui-button float-left"></button> &nbsp;
          <button pButton type="button" label="Leave call" (click)="leaveCall()"
            *ngIf="!isCallDisconnected && is3WayCall" class="ui-button float-left"></button> &nbsp;
          <button pButton type="button" label="Hold" (click)="changeCallStatus('PARK_CUSTOMER')"
            *ngIf="!isCallDisconnected && !isHold" class="ui-button float-left"></button> &nbsp;
          <button pButton type="button" label="Resume" class="ui-button float-left blink-red"
            *ngIf="isHold && !isCallDisconnected" (click)="changeCallStatus('GRAB_CUSTOMER')"></button> &nbsp;
          <button pButton type="button" label="End Call" (click)="sendEventToIframe('hangup')"
            style="background-color:red; border: 1px solid red" class="ui-button float-right"></button>

        </div>
      </div>
    </panel>
  </div>
</common-dialog>


<!-- Call Dialogue -->
<common-dialog [dialogConfig]="callHangupDialog1" (closeModal)="controlModalDispo(false)">
  <div class="cal-hangup-reason">
    <panel [panelConfig]="viewPanel2">
      <div panelHeader>
        <span>Disposition Screen <span (click)="controlModalDispo(false)" class="pushToBack"> Hide </span></span>
      </div>
      <div panelBody>

        <div class="ticketIdDispo">
          <div class="p-grid">
            <div class="p-col-6">
              <p class="generalLabel" [ngClass]="{'invalidTicketText': isInvalidTicket}">Ticket Id</p>
              <input type="text" [ngClass]="{'invalidTicketInput': isInvalidTicket}" pInputText
                placeholder="Ticket Id. eg : TK2101" [(ngModel)]="ticketID" class="ticketIdTextField" />
              <span class="invalidTicketMessage" *ngIf="isInvalidTicket"> <br>
                <p>Invalid Ticket Id</p>
              </span>
            </div>
            <div class="p-col-6">
              <p class="generalLabel">Phone#</p>
              <input type="text" pInputText placeholder="" readonly [(ngModel)]="incomingPhoneNumber"
                class="ticketIdTextField" />
            </div>
          </div>


        </div>
        <p class="generalLabel">Disposition</p>
        <div class="disposition-list">
          <div class="" *ngFor="let reason of callHangUpReason">
            <!-- <label class="container-radiobox">{{reason.name}}
        <input type="radio" [checked]="reason.selected" name="radio" (change)="selectDisposition(reason)">
        <span class="checkmark"></span>
      </label> -->
            <label class="container-radiobox">{{ reason.displayName }}
              <input type="radio" [checked]="reason.selected" name="radio" (change)="selectDisposition(reason)" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <br>
        <p class="generalLabel">Call Notes</p>
        <!-- <editor (text)="setCallNotes($event)" #resolveEditor></editor> -->
        <textarea pInputTextarea id="Comments" class="textarea-callhangup" [(ngModel)]="callNotes"></textarea>
        <br>
        <div class="btm-btns">
          <button pButton type="button" label="Dispose Call" (click)="updateDisposition()"
            [disabled]="!selectedDisposition.selected" class="ui-button"></button>
        </div>
      </div>
    </panel>
  </div>
</common-dialog>
<!-- Call Dialogue -->
<common-dialog [dialogConfig]="callTransferDialog">
  <div class="cal-hangup-reason">
    <panel [panelConfig]="viewPanel2">
      <div panelHeader>
        <span>Call Transfer</span>
      </div>
      <div panelBody>
        <div class="ticketIdDispo">
          <p class="generalLabel">Transfer Type : </p>
          <br>
          <label class="container-radiobox" (change)="transferType = 'BLIND'">Blind Transfer
            <input type="radio" [checked]="transferType == 'BLIND'" name="radioTransfer">
            <span class="checkmark"></span>
          </label>

          <label class="container-radiobox" (change)="transferType = '3WAY'">3Way - Conference
            <input type="radio" [checked]="transferType == '3WAY'" name="radioTransfer">
            <span class="checkmark"></span>
          </label>
          <br>
          <p class="generalLabel">Call To Number :</p>
          <br>
          <p-dropdown (onChange)="onAgentSelectChange($event)" appendTo="body" [options]="agentsList"
            optionLabel="agent_value" styleClass="selector" placeholder="Select Agent"></p-dropdown>
          <input type="number" maxlength="11" style="width: 95%;" *ngIf="selectedAgent == 'External'" pInputText
            placeholder="Enter External Number" [(ngModel)]="callToNumber" class="ticketIdTextField" />
          <br>
          <span class="invalidTicketText" *ngIf="isInvalidNumber">Please Enter valid Number</span>
        </div>
        <br>
        <div class="btm-btns">
          <button pButton type="button" label="Transfer Call" (click)="transferCall()" [disabled]="!
    transferType || !callToNumber" class="ui-button"></button>

          <!-- <button pButton type="button" label="Park Call" (click)="parkCall()" [disabled]="!
    transferType || !callToNumber" class="ui-button"></button>

          <button pButton type="button" label="Grab Call" (click)="unparkCall()" [disabled]="!
    transferType || !callToNumber" class="ui-button"></button>

          <button pButton type="button" label="Leave Call" (click)="leaveCall()" [disabled]="!
    transferType || !callToNumber" class="ui-button"></button> -->
        </div>
      </div>
    </panel>
  </div>
</common-dialog>



<common-dialog [dialogConfig]="callTransferAlert">
  <div class="cal-hangup-reason">
    <panel [panelConfig]="viewPanel2">
      <div panelHeader>
        <span>Alert</span>
      </div>
      <div panelBody>
        <h4>A call is being transferred to your extension. Please answer it from the soft phone. Call Type is
          "{{alertData.type}}".</h4>

        <div class="btm-btns">
          <button pButton type="button" label="Close" (click)="closeAlertDialog()" class="ui-button"></button>
        </div>

      </div>
    </panel>
  </div>
</common-dialog>