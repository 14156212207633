<!-- Add and Edit Header -->
<p-menubar stylceClass="customMenu" *ngIf="addButton||editButton">
    <span class="menuHeading">{{dataTable?.menuHeading}}</span>
    <button pButton label="Add" class="add_button" icon="pi pi-plus" *ngIf="addButton"
        (click)="openDialogue('add','')"></button>
</p-menubar>

<!-- Data Table Starts -->
<p-table #dt styleClass="ui-table" [columns]="columns" [value]="data" [lazy]="false" (onLazyLoad)="loadCarsLazy($event)"
    [paginator]="true" [rows]="rows" [totalRecords]="totalRecords" [filterDelay]="0" [loading]="loading"
    [showCurrentPageReport]="true" [rowsPerPageOptions]="pageSizeOptions" paginatorDropdownAppendTo="body"
    [globalFilterFields]="fields" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [exportFilename]="dataTable?.menuHeading">

    <ng-template pTemplate="caption">

        <div class="ui-helper-clearfix" style="text-align: left">

            <button type="button" pButton icon="pi pi-file-o" iconPos="left" label="CSV" (click)="dt.exportCSV()"
                class="p-btn-sm" style="margin-right: 0.5em;"></button>
            <button type="button" pButton icon="pi pi-file-excel" iconPos="left" label="EXCEL" (click)="exportExcel()"
                style="margin-right: 0.5em;" class="ui-button-success p-btn-sm"></button>
            <button type="button" pButton icon="pi pi-file-pdf" iconPos="left" label="PDF" (click)="exportPdf()"
                class="ui-button-warning p-btn-sm"></button>

            <div class="ui-table-globalfilter-container">
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search" />
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [ngClass]="col.width" pSortableColumn="{{col.field}}">
                {{col.header}} <p-sortIcon field="{{col.field}}"></p-sortIcon>
            </th>
            <th *ngIf="viewButton || editButton || approveButton || openButton || deleteButton" class="MEDIUM_1"> Action
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
                <span class="inputData" *ngIf="!col.type && col.field!='createdAt' ">
                    {{(rowData[col.field])?rowData[col.field]:'-'}}
                </span>
                <span *ngIf="!col.type && col.field =='createdAt'">
                    {{rowData[col.field] | date :'EEE, dd MMM yyyy h:mm a'}}
                </span>
                <span *ngIf="col.type && col.type =='date'">
                    {{rowData[col.field] | date :'EEE, dd MMM yyyy h:mm a'}}
                </span>
                <!-- <span *ngIf="col.field == 'status'" >
                    {{(rowData[col.field] === true || rowData[col.field] === "ACTIVE" || rowData[col.field] === "1") ?'Active': 'Inactive'}}</span> -->

            </td>
            <td *ngIf="viewButton || editButton || approveButton || openButton || deleteButton"
                class="text-center action-btns">
                <span *ngIf="editButton" (click)="openDialogue('edit', rowData)"> <i class="fas fa-edit pointer"
                        title="Edit"></i></span>

                <span *ngIf="viewButton" (click)="selectedData('view',rowData)"> <i *ngIf="viewButton"
                        class="fas fa-eye pointer" title="View"></i> </span>

                <span *ngIf="approveButton" class="pointer" (click)="selectedData('approve',rowData)"><i
                        class="fas fa-check pointer success-color" title="Approve"></i> </span>

                <span *ngIf="openButton" class="pointer" (click)="selectedData('open',rowData)"><i
                        class="fas fa-external-link-alt" title="Open"></i> Edit</span>

                <span *ngIf="deleteButton" (click)="deleteEvent(rowData)"> <i class="fas fa-trash pointer"
                        title="Delete"></i></span>

            </td>
            <!-- <td *ngIf="viewButton" class="text-center" (click)="selectedData(rowData)">
                <i class="fas fa-eye pointer"></i>
            </td>
            <td *ngIf="approveButton" class="text-center" (click)="selectedData(rowData)">
                <i class="fas fa-check pointer success-c\olor"></i>
            </td>
            <td *ngIf="openButton" class="text-center" (click)="selectedData(rowData)">
                <i class="fas fa-times pointer danger-color"></i>
            </td>
             -->
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="8" style="text-align:left">No Data found.</td>
        </tr>
    </ng-template>
</p-table>

<!-- Default Dialogue Starts -->
<common-dialog [dialogConfig]="defaultDialog">
    <ng-content></ng-content>
</common-dialog>

<confirmation-dialog #confirmDialog (confirm)="confirmDelete($event)"></confirmation-dialog>