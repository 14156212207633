import { Component, OnInit, Input, SimpleChanges, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

// import { ModalService } from '../../../services/modal.service';
import { EModalType, IConfirmDialog } from '../../../models/modules/shared/dialog.model';

@Component({
  selector: 'viewMessage-modal',
  templateUrl: './modal-view-timeline.component.html',
  styleUrls: ['./modal-view-timeline.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalViewTimelineComponent implements OnInit {

  //@ViewChild("homeComponent") homeComponent: HomeComponent;

  @Output() confirm = new EventEmitter();

  modalDialog = {
    visible: false,
    type: null,
    message: '',
    imgSrc: ''
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes)
  }

  // Success dialog
  successDialog(message: string = 'Success') {
    this.modalDialog.visible = true;
    this.modalDialog.type = EModalType.SUCCESS;
    this.modalDialog.imgSrc="../../../../assets/images/success.svg"
    this.modalDialog.message = message;
  }

  // Error dialog
  errorDialog(message: string = 'Error') {
    this.modalDialog.visible = true;
    this.modalDialog.type = EModalType.ERROR;
    this.modalDialog.imgSrc="../../../../assets/images/error.svg"
    this.modalDialog.message = message;
  }

  // Info dialog
  infoDialog(message: string = 'Info') {
    this.modalDialog.visible = true;
    this.modalDialog.type = EModalType.INFO;
    this.modalDialog.imgSrc="../../../../assets/images/information.svg"
    this.modalDialog.message = message;
  }

  // Waring dialog
  warnDialog(message: string = 'Warning') {
    this.modalDialog.visible = true;
    this.modalDialog.type = EModalType.WARNING;
    this.modalDialog.imgSrc="../../../../assets/images/information.svg"
    this.modalDialog.message = message;
  }
  // Close modal
  closeModal() {
    this.modalDialog.visible = false;
    this.modalDialog.type = null;
    this.modalDialog.message = '';
  }

  //View Ticket
  viewTicketModal()
  {
    this.closeModal();
    this.confirm.emit(true);
  }
}
