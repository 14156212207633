import { Component, OnInit,ViewChild ,ElementRef } from '@angular/core';
import { IPanel } from '../../../models/modules/shared/panel.model';
import { ApiService } from '../../../services/api.service';
import { FormGroup, FormBuilder ,FormControl , Validators } from '@angular/forms';
import { ModalComponent } from '../../shared/modal/modal.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit {
  @ViewChild("messageModal") messageModal: ModalComponent;
  @ViewChild('createFile') createFile: ElementRef;

  assetForm : FormGroup
  currentStatus : any = 'add'
  selectedService : any
  uploadFileName : any
  uploadedId : any

  constructor(private apiService : ApiService,private fb : FormBuilder) { 
    this.assetForm = this.fb.group({
      form_name: new FormControl(null, Validators.required),
      sharable_link: new FormControl(null),
      description: new FormControl(null, Validators.required),
      instruction: new FormControl(null, Validators.required),
      form_type : new FormControl(null, Validators.required),
      document : new FormControl(null)
    })
  }
  serviceList : any = []
  totalAssets : any = []
  currentList : any = []
  selectedAssets : any = {}

  ngOnInit(): void {
    // this.getServiceTypeList()
    // this.getAssetsList()
  }

  ngAfterViewInit(){
    this.getServiceTypeList()
    this.getAssetsList()
  }

  pageInit(){
    console.log("PageInit")
    this.getServiceTypeList()
    this.getAssetsList()
  }


  viewPanel1: IPanel = {
    showHeader: true
  }

  async getServiceTypeList() {
    let response = await this.apiService.get(`get-service-type?sort=ASC&orderBy=name`).toPromise()
    this.serviceList = response.data
  }

  selectAssets(value){
    this.selectedAssets = value
    console.log(this.totalAssets)
    if(value.document){
      this.uploadedId =value.document.id
      value.document = (value.document.filename)?value.document.filename:value.document
    }
    this.assetForm.patchValue(value)
    this.currentStatus = 'edit'
  }

  async getAssetsList(){
    console.log(this.selectedService)
    let response = await this.apiService.get(`get-asset-master?service_id=${(this.selectedService)?this.selectedService.id:''}`).toPromise()
    this.totalAssets = response.data
    this.currentList = this.totalAssets
  }

  showService(){
    console.log(this.selectedService)
    this.getAssetsList()
  }

  async updateAssets(){
    this.assetForm.value['id'] = this.selectedAssets.id
    this.assetForm.value['document'] = (this.uploadedId)?this.uploadedId : null
    this.assetForm['value']['instruction'] = this.constructStringByArray(this.assetForm.value['instruction'])
    let response = await this.apiService.put('update-asset-master',this.assetForm.value).toPromise()
    console.log(response)
    if(response.status){
      this.createFile.nativeElement.value = "";
      this.messageModal.successDialog(response.message)
      this.getAssetsList()
      this.assetForm.reset()
      this.uploadedId = ''
      this.currentStatus =  'add'
      this.selectedAssets = {}
    }
  }

  async updateAssetsToService(){
    console.log(this.totalAssets)
    let asset_id = this.totalAssets.filter((e)=>{
      if(e.isChecked){
        return e.id
      }
    })
    let request = {
      service_type : this.selectedService.id,
      assets : asset_id,
      status : true
    }
    let response = await this.apiService.post('create-asset-service',request).toPromise()
    if(response.status){
      this.messageModal.successDialog(response.message)
      this.getAssetsList()
    }
  }

  async addAssets(){
    this.assetForm.value['document'] = (this.uploadedId)?this.uploadedId : null
    this.assetForm['value']['instruction'] = this.constructStringByArray(this.assetForm.value['instruction'])
    let response = await this.apiService.post('create-asset-master',this.assetForm.value).toPromise()
    if(response.status){
      this.messageModal.successDialog(response.message)
      this.currentStatus =  'add'
      this.getAssetsList()
      this.assetForm.reset()
      this.uploadedId = ''
      this.createFile.nativeElement.value = "";
    }
  }

  // Construct string to array of string by enter
  constructStringByArray(_string) {
    let _array = [];

    if (!_string) return _array;

    console.log('_string', _string)
    // _array = _string.split('\n');
    _array = _string[0].split("\n");

    return _array;


  }


  onFileChange(fileInput) {
    console.log(fileInput)
    const file = fileInput.target.files[0];
    console.log(file)
    if (file) {
      this.uploadFileName = file.name
      console.log(this.uploadFileName)
      const formData = new FormData();
      formData.append('file', file);

      this.apiService.post('upload-file', formData).subscribe(res => {
        console.warn("UUPP",res)
        this.assetForm.get('document').setValue(this.uploadFileName)
        let shareLink = environment.api_img_url + res['files']['url']
        this.assetForm.get('sharable_link').setValue(shareLink)
        if (!res.status) return;

        this.uploadedId = res['files']['id'];

      });
    } else {
      this.uploadFileName = '';
      this.uploadedId = null;
    }

  }

  setFormName(value){
    this.assetForm.get('form_name').setValue(value)
  }
  

}
