<div class="sidebar-navigation basic-bg-2 basic-bg-2-border">

  <div class="profile">
    <div class="profile-picture">
      <!-- <img src="../../../../assets/images/photo.png" alt="profile"> -->
      <img [src]="image_url" alt="profile" (error)="handleImageError($event)">
    </div>
    <h5 class="margin-0">{{_session['fullname'] || ''}}</h5>
    <p class="role margin-0 goldenrod-color">{{_session['designationName'] || ''}} -
      {{_session['department']['department_name'] || ''}}</p>
    <a (click)="getUserDetails()" class="view-profile"> <i class="fas fa-eye"></i> View Profile</a>
  </div>
  <div class="sidebar-floatMenu">
    <div id="SideNav" class="sidenav">
      <a id="dashboardMenu" (click)="onselectMenu('dashboardMenu'); navigate('dashboard')"><i
          class="fas fa-tachometer-alt"></i> &nbsp;Dashboard</a>
      <!-- <a id="notificationMenu" (click)="onselectMenu('notificationMenu'); navigate('notification')"><i class="fa fa-bell"></i> &nbsp;Notifications</a> -->
      <a *ngIf="userConfig && userConfig['charts']" id="chartsMenu"
        (click)="onselectMenu('chartsMenu'); navigate('charts')"><i class="fas fa-chart-pie"></i> &nbsp;Charts</a>
      <!-- <a id="appointmentMenu" (click)="onselectMenu('appointmentMenu'); navigate('appointment')"><i
          class="fas fa-calendar-check"></i> &nbsp;Appointment</a>
      <a id="calendarMenu" (click)="onselectMenu('tasksMenu'); openCalendar()"><i class="far fa-calendar"></i>
        &nbsp;Calendar</a> -->
      <a id="knowledgeBase" (click)="onselectMenu('knowledgeBase'); navigate('knowledge-base')"><i
          class="fas fa-server"></i> &nbsp;Knowledge Base</a>
      <!-- <a id="formsMenu" (click)="onselectMenu('formsMenu'); navigate('forms')"><i class="fa fa-window-maximize"></i> &nbsp;Forms</a> -->
      <a id="tasksMenu" (click)="onselectMenu('tasksMenu'); openTasks()"><i class="fas fa-tasks"></i> &nbsp;Tasks</a>
      <a *ngIf="userConfig && userConfig['management']" id="managementMenu" class="dropdown-btn"
        (click)="onselectMenu( 'managementMenu' , 'managementSubMenu')"><i class="fas fa-cogs"></i> &nbsp;Management
        <i class="fa fa-caret-down"></i></a>
      <div id="managementSubMenu" class="dropdown-container">
        <a id="assets" (click)="onselectMenu('assets');openAssets();">Assets</a>
        <a id="department" (click)="onselectMenu('department'); navigate('department')">Department</a>
        <a id="roles" (click)="onselectMenu('roles'); navigate('role')">Roles</a>
        <a id="supportGroup" (click)="onselectMenu('supportGroup'); navigate('support-group')">Support Group</a>
        <a id="supportLevel" (click)="onselectMenu('supportLevel'); navigate('support-level')">Support Level</a>
        <a id="slaMatrixMenu" (click)="onselectMenu('slaMatrixMenu'); navigate('sla')">SLA Model</a>
        <a id="slaConfigMenu" (click)="onselectMenu('slaConfigMenu'); navigate('sla-config')">SLA Events</a>
        <a id="applicationMenu" (click)="onselectMenu('applicationMenu'); navigate('service-type')">Service
          Types</a>
        <a id="Assettypes" (click)="onselectMenu('Assettypes'); navigate('asset-type')">Asset Types</a>
        <a id="usersMenu" (click)="onselectMenu('usersMenu'); navigate('user')">Users</a>
        <a id="reportsMenu" (click)="onselectMenu('reportsMenu'); navigate('report')">Reports</a>
        <a id="problemsMenu" (click)="onselectMenu('problemsMenu'); navigate('problems')">Problems</a>
        <a id="incidentsMenu" (click)="onselectMenu('incidentsMenu'); navigate('incident')">Incidents</a>
        <a id="organisationMenu" (click)="onselectMenu('organisationMenu'); navigate('organisation')">Organisation</a>
        <a id="contactInfo" (click)="onselectMenu('contactInfo'); navigate('contact-info')">Contact Info</a>
        <a id="faq" (click)="onselectMenu('faq'); navigate('frequently-asked')">Frequently Asked Questions(FAQ)</a>
        <a id="announcements" (click)="onselectMenu('announcements'); navigate('announcements')">Announcements</a>

        <!-- <a id="areaMenu" (click)="onselectMenu('areaMenu')">Area</a>
                <a id="ticketTypeMenu" (click)="onselectMenu('ticketTypeMenu')">Ticket Type</a>
                <a id="categoryMenu" (click)="onselectMenu('categoryMenu')">Category</a>
                <a id="subCategoryMenu" (click)="onselectMenu('subCategoryMenu')">Sub Category</a> -->
            </div>
            <!-- <a (click)="_logout()"><i class="fas fa-sign-out-alt"></i>  &nbsp;Logout</a> -->

        </div>
        <div class="float-menu-container">
            <div class="floatMenu" [class.visible]="isFloatMenuVisible">
                <a style="padding-bottom: 0; display: block; text-align: center;">Ticket status</a>
                <ul class="ticket-status-list">
                    <li *ngFor="let status of ticketStatusCount">
                        <span class="ticket-status-label">{{status.label}}</span>
                        <span class="ticketstatusCls" [ngStyle]="{'backgroundColor': status.colorCode}">{{status.value ||
                            '0'}}</span>
                    </li>

                </ul>
            </div>
            <div class="floatingBtn" (click)="toggleFloatMenu()">
                <ul class="ticket-status-list ticket-button">
                    <li *ngFor="let status of ticketStatusCount">
                        <!-- <span class="ticket-status-label">{{status.label}}</span> -->
                        <span class="ticketstatusCls" [ngStyle]="{'backgroundColor': status.colorCode}">{{status.value ||
                            '0'}}</span>
                    </li>
                    <li style="width: 50%;" class="arrow" [ngClass]="{'rotated': isArrowRotated}"></li>
                </ul>
            </div>
        </div>
    </div>
</div>


<common-dialog [dialogConfig]="viewDialog" (closeModal)="closeViewProfile($event)">

  <user-profile-view *ngIf="viewProfileData" [formData]="viewProfileData"
    (profileImgChanged)="ngOnInit(); getUserDetails()"></user-profile-view>
</common-dialog>

<common-dialog [dialogConfig]="assetsDialogue" (closeModal)="closeViewProfile($event)">
  <assets *ngIf="assetViewed"></assets>
</common-dialog>

<common-dialog [dialogConfig]="taskDialog" class="task-modal" (closeModal)="closeViewProfile($event)">
  <app-tasks *ngIf="taskViewed"></app-tasks>
</common-dialog>

<!-- Calendar Iframe fetched from calendar component -->
<common-dialog [dialogConfig]="calendarModalDialogue" (closeModal)="closeViewProfile($event)">
  <app-calendar></app-calendar>
</common-dialog>
