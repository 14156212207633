
<p-dialog [(visible)]="dialogConfig.display" 
    [closable]="(dialogConfig.showCloseButton) ? true : false"
    [modal]="(dialogConfig.showBackDrop) ? true : false"
    [draggable]="(dialogConfig.draggable) ? true : false"
    [resizable]="(dialogConfig.resizable) ? true : false"
    [blockScroll]="(dialogConfig.blockScroll) ? true : false"
    [closeOnEscape]="(dialogConfig.closeOnEscape) ? true : false"
    [maximizable]="(dialogConfig.maximizable) ? true : false"
    [keepInViewport]="(dialogConfig.keepInViewport) ? true : false"
    [showHeader]="(dialogConfig.showHeader) ? true : false"
    styleClass="customDialog"
    [style]="_style"
    >
    <!-- <i class="fas fa-times-circle custom-close"></i> -->
    <img *ngIf="dialogConfig.showCustomCloseButton" src="../../../../assets/images/close.png" alt="Close" (click)="closeDialog()" class="custom-close" [ngClass]="{'medium': dialogConfig.size == refSizeType.MEDIUM, 'small': dialogConfig.size == refSizeType.SMALL}">
    <p-header >
        {{dialogConfig.headerLabel}} 
    </p-header>
        <ng-content></ng-content>
    <p-footer *ngIf="dialogConfig.showFooterButtons">
        <button  styleClass="custom-btn"  *ngIf="dialogConfig.confirmButton" type="button" pButton icon="pi pi-check" class="ui-button-success">{{ dialogConfig.confirmButtonLabel ||"CONFIRM"}}</button>
        <button styleClass="custom-btn" *ngIf="dialogConfig.rejectButton" type="button" pButton icon="pi pi-times" class="ui-button-danger">{{ dialogConfig.rejectButtonLabel ||"CANCEL"}}</button>
        <button  styleClass="custom-btn"  *ngIf="dialogConfig.closeButton" type="button" pButton icon="pi pi-times" class="ui-button-secondary">{{ dialogConfig.closeButtonLabel ||"CLOSE"}}</button>

    </p-footer>
</p-dialog>

