<form [formGroup]="createIntakeForm" (ngSubmit)="saveForm()" class="intakeFormCls">
    <div class="form-group">
        <div class="p-col-12 p-grid">

            <div class="p-col-6">
                <label class="labelClass">Enquiry No </label> &nbsp;&nbsp;
                <input id="nric" type="text" maxlength="12" style="height: 29px;" minlength="0" pInputText formControlName="enquiry_no" class="form-control" disabled>&nbsp;&nbsp;
                <span (click)="getCustomerData()"><i class="fa fa-search" style="cursor: pointer;"
                        aria-hidden="true"></i>
                </span>
            </div>

            <div class="p-col-6">
                <img style="width: 50%; float: right;" src="../../../../assets/images/btmlogo.png">
            </div>


        </div>

        <div class="p-col-12 p-grid" style="padding-bottom: 0;padding-top: 0;">
            <label class="p-col-12 labelCls" style="font-weight: bold; font-size: 0.85rem; padding-bottom: 0;">Customer
                Enquiry</label>
            <div class="p-col-3" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Date </label>
                <input id="nric" type="date" maxlength="12" formControlName="date" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
            <div class="p-col-2" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Time </label>
                <input id="nric" type="time" maxlength="12" formControlName="time" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
            <div class="p-col-2" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Enquiry Type </label>
                <input id="nric" type="text" maxlength="30" formControlName="enquiry_type" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
            <div class="p-col-3" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Call Received By </label>
                <input id="nric" type="text" maxlength="30" formControlName="call_received_by" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
            <div class="p-col-2" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Assigned To </label>
                <input id="nric" type="text" maxlength="30" formControlName="assigned_to" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
        </div>


        <div class="p-col-12 p-grid" style="padding-bottom: 0;padding-top: 0;">
            <br>
            <label class="p-col-12 labelCls" style="font-weight: bold; font-size: 0.85rem; margin-top: 1%;">Enquiry
                Details</label>
            <br>

            <label class="p-col-12 labelCls" style="font-weight: bold; padding-bottom: 0;">Caller Information</label>
            <div class="p-col-2" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Salutation </label>
                <input id="salutation" type="text" formControlName="caller_salutation" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
            <div class="p-col-4" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Caller Name </label>
                <input id="caller_name" type="text" maxlength="30" formControlName="caller_name" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
                <p-message for="caller_name" severity="error" text="Only alphabets are allowed" *ngIf="!createIntakeForm.controls['caller_name'].valid&&createIntakeForm.controls['caller_name'].dirty">
                </p-message>
            </div>
            <div class="p-col-3" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Caller Telephone </label>
                <input id="nric" type="number" maxlength="12" formControlName="caller_telephone" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
            <div class="p-col-3" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Email Address </label>
                <input id="email" type="text" maxlength="40" formControlName="caller_email_address" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
                <p-message for="email" severity="error" text="Enter valid email address" *ngIf="!createIntakeForm.controls['caller_email_address'].valid&&createIntakeForm.controls['caller_email_address'].dirty">
                </p-message>
            </div>
            <div class="p-col-12" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Caller Home Address </label>
                <input id="nric" type="text" maxlength="100" formControlName="caller_home_address" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>

        </div>
        <div style="margin-top: 1%;"></div>
        <label class="p-col-12 labelCls" style="font-weight: bold; font-size: 0.85rem;">Client Information</label>
        <div class="p-col-12 p-grid">
            <div class="p-col-2" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Salutation </label>
                <input id="nric" type="text" maxlength="12" formControlName="client_salutation" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
            <div class="p-col-3" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Client Name </label>
                <input id="client_name" type="text" maxlength="30" formControlName="client_name" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
                <p-message for="client_name" severity="error" text="Only alphabets are allowed" *ngIf="!createIntakeForm.controls['client_name'].valid&&createIntakeForm.controls['client_name'].dirty">
                </p-message>
            </div>
            <div class="p-col-2" style="padding-right: 0;padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Sex </label>
                <input id="nric" type="text" maxlength="20" formControlName="client_sex" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
            <div class="p-col-2" style="padding-left: 0;padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Age </label>
                <input id="nric" type="number" maxlength="3" formControlName="client_age" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
            <div class="p-col-3" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Client Telephone </label>
                <input id="nric" type="number" maxlength="12" formControlName="client_telephone" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
            <div class="p-col-12" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Client Home Address </label>
                <input id="nric" type="text" maxlength="100" formControlName="client_home_address" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>


            <div class="p-col-4" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Relationship with Caller </label>
                <input id="nric" type="text" maxlength="30" formControlName="relationship_with_caller" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>


            <div class="p-col-4" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Current location </label>
                <input id="nric" type="text" maxlength="30" formControlName="current_location" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>

            <div class="p-col-4" style="padding-bottom: 0;padding-top: 0;">
                <label class="labelClass">Preferred Language/Dialect </label>
                <input id="nric" type="text" maxlength="30" formControlName="preferred_language" minlength="0" pInputText autocomplete="new-password" class="form-control inputcls">
            </div>
        </div>

        <label class="p-col-12 labelCls" style="font-weight: bold;">Source of Referral</label>
        <div class="p-col-12 p-grid">
            <div class="p-col-12">
                <input type="radio" formControlName="source_of_referral" value="Advertisement" id="Advertisement">
                <label for="Advertisement" class="labelCls">Advertisement</label> &nbsp;
                <input type="radio" formControlName="source_of_referral" value="Brochure" id="Brochure">
                <label for="Brochure" class="labelCls">Brochure</label> &nbsp;
                <input type="radio" formControlName="source_of_referral" value="BusinessAssociate" id="BusinessAssociate">
                <label for="BusinessAssociate" class="labelCls">Business Associate</label> &nbsp;
                <input type="radio" formControlName="source_of_referral" value="SEO" id="SEO">
                <label for="SEO" class="labelCls">SEO(Search Engine Optimisation)</label>
            </div>
            <div class="p-col-12">
                <input type="radio" formControlName="source_of_referral" value="SocialMedia" id="SocialMedia">
                <label for="SocialMedia" class="labelCls">Social Media</label> &nbsp;
                <input type="radio" formControlName="source_of_referral" value="Staff" id="Staff">
                <label for="Staff" class="labelCls">Staff</label> &nbsp;
                <input type="radio" formControlName="source_of_referral" value="Events" id="Events">
                <label for="Events" class="labelCls">Events</label> &nbsp;
                <input type="radio" formControlName="source_of_referral" value="Others" id="Others">
                <label for="Others" class="labelCls">Others</label> &nbsp;
                <input type="radio" formControlName="source_of_referral" value="NotSure" id="NotSure">
                <label for="NotSure" class="labelCls">Not Sure</label> &nbsp;
                <input style="width: 30%; height: 35px;" id="nric" formControlName="source_of_referral" type="text" placeholder="Remarks:" pInputText class="form-control">
            </div>
        </div>

        <label class="p-col-12 labelClass">Compliaints, History (Duration of Symptoms, New problem?) & Chronic
            Conditions</label>
        <!-- <div class="p-col-12"> -->
        <input id="nric" type="text" maxlength="30" formControlName="complaint" style="margin-left: 8px; width: 100%; height: 35px;" minlength="0" pInputText autocomplete="new-password" class="form-control">
        <!-- </div>   -->
        <div class="p-col-12">
            <div class="p-col-12 p-grid labelCls" style="padding-bottom: 0;margin-bottom: 0;">
                <label class="labelCls">Seen a doctor? </label> &nbsp;
                <input type="radio" id="Yes" value="Yes" formControlName="seen_a_doctor">
                <label for="Yes">Yes</label>&nbsp;
                <input type="radio" id="No" value="No" formControlName="seen_a_doctor">
                <label for="No">No</label>
            </div>
            <label style="width: 100%; padding-top: 0;" class="labelCls">If no, advise client to seek medical attention,
                If
                symptoms are stable, continue with assessment</label>
        </div>

        <label class="p-col-12 labelCls" style="font-weight: bold;">Verbal Assessment</label>
        <div class="p-col-12 p-grid">
            <div class="p-col-12" style="padding-left: 0;">
                <table style="width: 100%;">
                    <td style="width: 10%;">
                        <label class="p-col-6 labelClass">Height (cms): </label>
                        <input type="number" formControlName="height">
                    </td>
                    <td style="width: 10%;">
                        <label class="p-col-6 labelClass">Weight (kgs): </label>
                        <input type="number" formControlName="weight">
                    </td>
                </table>
            </div>
            <label class="p-col-12 labelClass">Activities of Daily Living (ADL): </label>
            <div class="p-col-12 labelCls" style="padding-bottom: 0;">

                <table style="width: 100%;">
                    <td style="width: 10%;">
                        <label>Feeding: </label>
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Independent F" formControlName="feeding" value="Independent">
                        <label for="Independent F">Independent</label>&nbsp;
                    </td>
                    <td style="width: 16%;">
                        <input type="radio" id="Minor Assistance F" formControlName="feeding" value="Minor Assistance">
                        <label for="Minor Assistance F">Minor Assistance</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Spoon feeding F" formControlName="feeding" value="Spoon feeding">
                        <label for="Spoon feeding F">Spoon feeding</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="NGT/PEG F" formControlName="feeding" value="NGT/PEG">
                        <label for="NGT/PEG F">NGT/PEG</label>&nbsp;
                    </td>
                </table>
            </div>
            <div class="p-col-12 labelCls" style="padding-bottom: 0;">
                <table style="width: 100%;">
                    <td style="width: 10%;">
                        <label>Bathing: </label>
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Independent B" formControlName="bathing" value="Independent">
                        <label for="Independent B">Independent</label>&nbsp;
                    </td>
                    <td style="width: 16%;">
                        <input type="radio" id="Minor Assistance B" formControlName="bathing" value="Minor Assistance">
                        <label for="Minor Assistance B">Minor Assistance</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Spoon feeding B" formControlName="bathing" value="Spoon feeding">
                        <label for="Spoon feeding B">Major Assistance</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Dependent B" formControlName="bathing" value="Dependent">
                        <label for="Dependent B">Dependent</label>&nbsp;
                    </td>
                </table>
            </div>
            <div class="p-col-12 labelCls" style="padding-bottom: 0;">
                <table style="width: 100%;">
                    <td style="width: 10%;">
                        <label>Toileting: </label>
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Independent T" formControlName="toileting" value="Independent">
                        <label for="Independent T">Independent</label>&nbsp;
                    </td>
                    <td style="width: 16%;">
                        <input type="radio" id="Minor Assistance T" formControlName="toileting" value="Minor Assistance">
                        <label for="Minor Assistance T">Minor Assistance</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Spoon feeding T" formControlName="toileting" value="Spoon feeding">
                        <label for="Spoon feeding T">Major Assistance</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Dependent T" formControlName="toileting" value="Dependent">
                        <label for="Dependent T">Dependent</label>&nbsp;
                    </td>
                </table>
            </div>
            <div class="p-col-12 labelCls" style="padding-bottom: 0;">
                <table style="width: 100%;">
                    <td style="width: 10%;">
                        <label>Grooming: </label>
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Independent G" formControlName="grooming" value="Independent">
                        <label for="Independent G">Independent</label>&nbsp;
                    </td>
                    <td style="width: 16%;">
                        <input type="radio" id="Minor Assistance G" formControlName="grooming" value="Minor Assistance">
                        <label for="Minor Assistance G">Minor Assistance</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Spoon feeding G" formControlName="grooming" value="Spoon feeding">
                        <label for="Spoon feeding G">Major Assistance</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Dependent G" formControlName="grooming" value="Dependent">
                        <label for="Dependent G">Dependent</label>&nbsp;
                    </td>
                </table>
            </div>
            <div class="p-col-12 labelCls" style="padding-bottom: 0;">

                <table style="width: 100%;">
                    <td style="width: 10%;">
                        <label>Ambulation: </label>
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Independent A" formControlName="ambulation" value="Independent">
                        <label for="Independent A">Independent</label>&nbsp;
                    </td>
                    <td style="width: 16%;">
                        <input type="radio" id="Walking aid A" formControlName="ambulation" value="Walking aid">
                        <label for="Walking aid A">Walking aid</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Wheelchair A" formControlName="ambulation" value="Wheelchair">
                        <label for="Wheelchair A">Wheelchair</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="None A" formControlName="ambulation" value="None">
                        <label for="None A">None</label>&nbsp;
                    </td>
                </table>
            </div>
            <div class="p-col-12 labelCls" style="padding-bottom: 0;">

                <table style="width: 100%;">
                    <td style="width: 10%;">
                        <label>Transfer: </label>
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Independent R" formControlName="transfer" value="Independent">
                        <label for="Independent R">Independent</label>&nbsp;
                    </td>
                    <td style="width: 16%;">
                        <input type="radio" id="Minor Assistance R" formControlName="transfer" value="Minor Assistance">
                        <label for="Minor Assistance R">Minor Assistance</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Spoon feeding R" formControlName="transfer" value="Major Assistance">
                        <label for="Spoon feeding R">Major Assistance</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Dependent R" formControlName="transfer" value="Dependent">
                        <label for="Dependent R">Dependent</label>&nbsp;
                    </td>
                </table>
            </div>
            <div class="p-col-12 labelCls">

                <table style="width: 100%;">
                    <td style="width: 10%;">
                        <label>Bed Mobility: </label>
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Independent M" formControlName="bed_mobility" value="Independent">
                        <label for="Independent M">Independent</label>&nbsp;
                    </td>
                    <td style="width: 16%;">
                        <input type="radio" id="Minor Assistance M" formControlName="bed_mobility" value="Minor Assistance">
                        <label for="Minor Assistance M">Minor Assistance</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Spoon feeding M" formControlName="bed_mobility" value="Major Assistance">
                        <label for="Spoon feeding M">Major Assistance</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Dependent M" formControlName="bed_mobility" value="Dependent">
                        <label for="Dependent M">Dependent</label>&nbsp;
                    </td>
                </table>
            </div>
        </div>

        <!-- <label class="p-col-12 labelCls" style="font-weight: bold;padding-left: 0;">Problem</label> -->
        <div class="p-col-12 p-grid">
            <div class="p-col-12 labelCls" style="padding-bottom: 0;padding-left: 0;">
                <table style="width: 100%;">
                    <td style="width: 15%;">
                        <input type="radio" id="NG Tube/PEG tube" formControlName="problem" value="NG Tube/PEG tube">
                        <label for="NG Tube/PEG tube">NG Tube/PEG tube</label>
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Oxygen/Suctioning" formControlName="problem" value="Oxygen/Suctioning">
                        <label for="Oxygen/Suctioning">Oxygen/Suctioning</label>
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Confusion/Memory Problems" formControlName="problem" value="Confusion/Memory Problems">
                        <label for="Confusion/Memory Problems">Confusion/Memory Problems</label>
                    </td>
                </table>
            </div>
            <div class="p-col-12 labelCls" style="padding-bottom: 0;padding-top: 0;padding-left: 0;">
                <table style="width: 100%;">
                    <td style="width: 15%;">
                        <input type="radio" id="Urinary Catheter" formControlName="problem" value="Urinary Catheter">
                        <label for="Urinary Catheter">Urinary Catheter</label>
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Tracheostomy" formControlName="problem" value="Tracheostomy">
                        <label for="Tracheostomy">Tracheostomy</label>
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Pain" formControlName="problem" value="Pain">
                        <label for="Pain">Pain</label>
                    </td>
                </table>
            </div>
            <div class="p-col-12 labelCls" style="padding-bottom: 0;padding-top: 0;padding-left: 0;">
                <table style="width: 100%;">
                    <td style="width: 15%;">
                        <input type="radio" id="Stoma" formControlName="problem" value="Stoma">
                        <label for="Stoma">Stoma</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Wound/Pressure ulcers" formControlName="problem" value="Wound/Pressure ulcers">
                        <label for="Wound/Pressure ulcers">Wound/Pressure ulcers</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Injections" formControlName="problem" value="Injections">
                        <label for="Injections">Injections</label>&nbsp;
                    </td>
                </table>
            </div>
            <div class="p-col-12 labelCls" style="padding-bottom: 0;padding-left: 0;">
                <table style="width: 100%;">
                    <td style="width: 5%;">
                        <input type="radio" id="Other issues/requests" formControlName="problem" value="Other issues/requests">
                        <label for="Other issues/requests">Other issues/requests</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input id="nric" type="text" maxlength="30" minlength="0" style="width: 100%;" pInputText autocomplete="new-password" class="form-control">
                    </td>
                </table>
            </div>
        </div>

        <label class="p-col-12 labelCls" style="font-weight: bold;padding-left: 0;">Recommendation/Service</label>
        <div class="p-col-12 p-grid">
            <div class="p-col-12 labelCls" style="padding-left: 0;padding-bottom: 0;">
                <table style="width: 100%;">
                    <td>
                        <input type="radio" id="Care Planner Package" formControlName="recomendation_service" value="Care Planner Package">
                        <label for="Care Planner Package">Care Planner Package</label>&nbsp;
                    </td>
                    <td>
                        <input type="radio" id="Care Coordination" formControlName="recomendation_service" value="Care Coordination">
                        <label for="Care Coordination">Care Coordination</label>&nbsp;
                    </td>
                    <td>
                        <input type="radio" id="Direct Referral" formControlName="recomendation_service" value="Direct Referral">
                        <label for="Direct Referral">Direct Referral</label>&nbsp;
                    </td>
                    <td>
                        <input type="radio" id="None" formControlName="recomendation_service" value="None">
                        <label for="None">None</label>&nbsp;
                    </td>
                </table>
            </div>
        </div>

        <label class="p-col-12 labelCls" style="font-weight: bold;padding: 0;">Services Referred</label>
        <div class="p-col-12 p-grid">
            <div class="p-col-12 labelCls" style="padding-bottom: 0;padding-top: 0;padding-left: 0;">
                <table style="width: 100%;">
                    <td style="width: 10%;">
                        <input type="radio" id="Home Care" formControlName="services_referred" value="Home Care">
                        <label for="Home Care">Home Care</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Home assessment & modification" formControlName="services_referred" value="Home assessment & modification">
                        <label for="Home assessment & modification">Home assessment & modification</label>&nbsp;
                    </td>
                </table>
            </div>
            <div class="p-col-12 labelCls" style="padding-bottom: 0;padding-top: 0;padding-left: 0;">
                <table style="width: 100%;">
                    <td style="width: 10%;">
                        <input type="radio" id="Home Nursing" formControlName="services_referred" value="Home Nursing">
                        <label for="Home Nursing">Home Nursing</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Dietician" formControlName="services_referred" value="Dietician">
                        <label for="Dietician">Dietician</label>&nbsp;
                    </td>
                </table>
            </div>
            <div class="p-col-12 labelCls" style="padding-bottom: 0;padding-top: 0;padding-left: 0;">
                <table style="width: 100%;">
                    <td style="width: 10%;">
                        <input type="radio" id="Physiotherapy" formControlName="services_referred" value="Physiotherapy">
                        <label for="Physiotherapy">Physiotherapy</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Nursing Home" formControlName="services_referred" value="Nursing Home">
                        <label for="Nursing Home">Nursing Home</label>&nbsp;
                    </td>
                </table>

            </div>
        </div>

        <label class="p-col-12 labelCls" style="font-weight: bold;padding-left: 0;">Regen Rehab Criteria:</label>
        <div class="p-col-12 p-grid">
            <div class="p-col-12 labelCls" style="padding-left: 0;">
                <table style="width: 100%;">
                    <td style="width: 15%;">
                        <input type="radio" id="Discharge from hospital, stable condition" formControlName="regen_rehab_criteria" value="Discharge from hospital, stable condition">
                        <label for="Discharge from hospital, stable condition">Discharge from hospital, stable
                            condition</label>&nbsp;
                    </td>
                    <td style="width: 15%;">
                        <input type="radio" id="Carer able to support" formControlName="regen_rehab_criteria" value="Carer able to support">
                        <label for="Carer able to support">Carer able to support</label>&nbsp;
                    </td>
                </table>
            </div>
        </div>
        <div class="p-grid align-end-button">
            <div class="p-col-12">
                <button pButton type="submit" class="subbutton" style="margin-right: 5px;">Submit</button>
                <button pButton type="button" class="clsbutton" (click)="clearForm()">Clear</button>
            </div>
        </div>
    </div>
</form>
<!-- </div> -->
<message-modal #messageModal></message-modal>