import { Component, OnInit, ViewEncapsulation, Input , ElementRef} from '@angular/core';
import { IPanel } from '../../../models/modules/shared/panel.model';

@Component({
  selector: 'panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PanelComponent implements OnInit {

  @Input() panelConfig: IPanel;

  constructor(private elementRef: ElementRef) { 
  }

  ngOnInit(): void {
  }

  ngAfterViewinit(){
    this.elementRef.nativeElement.style.setProperty('--color',this.panelConfig.headerColor);
  }

}
