import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { IDataTable } from '../../../models/modules/shared/data-table.model';
import {
  IDialog,
  EDialogSize,
} from '../../../models/modules/shared/dialog.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';

interface formType {
  type: string;
  value?: Object;
}

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css'],
})
export class DataTableComponent implements OnInit, OnChanges {
  @Input() dataTable: IDataTable;
  @Input() closeDialog: any;
  @Output()
  OutputData: EventEmitter<Object> = new EventEmitter<Object>();
  @ViewChild('confirmDialog') confirmDialog: ConfirmationDialogComponent;

  selectRow: any;
  columns: any[];
  data: any[];
  fields: any[];
  filter: any;

  scrollHeight: string = '20vh';
  rows: number = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  totalRecords: number = 0;
  loading: boolean = false;
  addButton: boolean = false;
  editButton: boolean = false;
  viewButton: boolean = false;
  deleteButton: boolean = false;
  approveButton: boolean = false;
  openButton: boolean = false;
  showColFilters: boolean = false;

  exportColumns: any[];

  defaultDialog: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    size: EDialogSize.LARGE,
    showCustomCloseButton: true,
    // keepInViewport: false
  };

  formData: formType = {
    type: 'add',
  };

  constructor() {}

  ngOnInit(): void {
    this.columns = this.dataTable.columns;
    this.data = this.dataTable.data;
    this.filter = this.dataTable.filter;
    this.scrollHeight = `${this.dataTable.scrollHeight}vh`;
    this.totalRecords = this.dataTable.totalRecords;
    // this.rows = this.dataTable.rows;
    this.addButton = this.dataTable.addButton ? true : false;
    this.editButton = this.dataTable.editButton ? true : false;
    this.viewButton = this.dataTable.viewButton ? true : false;
    this.deleteButton = this.dataTable.deleteButton ? true : false;
    this.approveButton = this.dataTable.approveButton ? true : false;
    this.openButton = this.dataTable.openButton ? true : false;
    this.showColFilters = this.dataTable.showColFilters ? true : false;
    if (this.dataTable.heading) {
      this.defaultDialog.showHeader = true;
      this.defaultDialog.headerLabel = this.dataTable.heading;
    }
    this.defaultDialog.size =
      this.dataTable.size == 'medium'
        ? EDialogSize.MEDIUM
        : this.dataTable.size == 'small'
        ? EDialogSize.SMALL
        : EDialogSize.LARGE;
    if (this.dataTable.size == 'medium_1') {
      this.defaultDialog.size = EDialogSize.MEDIUM_1;
    }
    if (this.dataTable.hideOverflowY) {
      this.defaultDialog.hideOverflowY = true;
    }
    console.log('CHECKOVERFLOW', this.dataTable);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataTable']) {
      console.log(changes['dataTable'].currentValue);
      this.dataTable = changes['dataTable'].currentValue;
      this.columns = this.dataTable.columns;
      this.fields = this.columns.map((e) => e.field);
      this.data = this.dataTable.data;
      this.scrollHeight = `${this.dataTable.scrollHeight}vh`;
      this.totalRecords = this.dataTable.totalRecords;
      // this.rows = this.dataTable.rows;
      this.addButton = this.dataTable.addButton ? true : false;
      this.editButton = this.dataTable.editButton ? true : false;
      this.viewButton = this.dataTable.viewButton ? true : false;
      this.deleteButton = this.dataTable.deleteButton ? true : false;
      this.approveButton = this.dataTable.approveButton ? true : false;
      this.openButton = this.dataTable.openButton ? true : false;
      this.showColFilters = this.dataTable.showColFilters ? true : false;
      if (this.dataTable.heading) {
        this.defaultDialog.showHeader = true;
        this.defaultDialog.headerLabel = this.dataTable.heading;
      }
      this.defaultDialog.size =
        this.dataTable.size == 'medium'
          ? EDialogSize.MEDIUM
          : this.dataTable.size == 'small'
          ? EDialogSize.SMALL
          : EDialogSize.LARGE;
      if (this.dataTable.size == 'medium_1') {
        this.defaultDialog.size = EDialogSize.MEDIUM_1;
      }
      console.log(this.defaultDialog);
    }
    if (changes.closeDialog && changes.closeDialog.currentValue) {
      console.log('Close dialog');
      this.defaultDialog.display = false;
    }
  }

  // getTableDate(tableData){
  //   console.log(tableData)
  //   this.columns = tableData.columns;
  //   this.data = tableData.data;
  //   this.scrollHeight = tableData.scrollHeight;
  //   this.totalRecords = tableData.totalRecords;
  //   this.rows = tableData.rows;
  //   this.addButton = (tableData.addButton)?true:false
  //   this.editButton = (tableData.editButton)?true:false
  //   this.defaultDialog.size = (tableData.size=='medium')? EDialogSize.MEDIUM : EDialogSize.LARGE
  // }

  loadCarsLazy(event: LazyLoadEvent) {
    console.log(event);
  }

  search(value) {
    console.log(value);
  }

  //Open Dialgue
  openDialogue(type, data) {
    console.log(type, data);
    this.defaultDialog.display = true;
    this.formData['type'] = type;
    if (type == 'edit') {
      console.log(data);
      this.formData.value = data;
    } else {
      this.formData.value = {};
    }
    console.log(this.formData);
    this.OutputData.emit(this.formData);
  }

  selectedData(type, data) {
    this.OutputData.emit({ type, data });
  }

  /******************************Export Options ********************************************** */

  // Export as pdf
  async exportPdf() {
    this.exportColumns = await this.columns.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));

    let options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default(0, 0);
        const _data = this.data;

        // Add logo as base64 using extractImageFromDataUrl
        var logo = new Image();

        environment.client == 'kgis'
          ? (logo.src = 'assets/images/helpdesklogo.png')
          : (logo.src = 'assets/images/aerodyne-green-logo.png');

        let logoHeight = 0;

        if (_data.length > 0) {
          _data.forEach(function (val, index) {
            const date = new Date(parseInt(val.createdAt));
            val['createdAt'] = date
              .toLocaleDateString('en', options)
              .toString();
          });

          // Add autoTable with header, data, and page number configuration
          doc.autoTable(this.exportColumns, _data, {
            startY: 30, // Adjust the starting Y position based on your logo height
            styles: {
              lineColor: [0.7, 0.7, 0.7], // Black color for borders
              lineWidth: 0.2, // Border width
            },
            addPageContent: function (data) {
              // Add page number at the top right corner
              doc.setFontSize(8);
              doc.text(
                'Page ' + data.pageNumber,
                doc.internal.pageSize.width - 30,
                10
              );

              // Add logo at the top left corner
              doc.addImage(logo, 'PNG', 10, 10, 30, 10);

              // Update startY for the next page
              data.settings.margin.top = 30 + logoHeight;
            },
            didDrawPage: function (data) {
              // Update the logo height after the first page
              if (data.pageNumber === 1) {
                logoHeight = 10; // Update this value based on the actual height of your logo
              }
            },
          });
        } else {
          // If no data, display a message
          doc.addImage(logo, 'PNG', 10, 10, 30, 10);

          // Text content
          var text = 'No data found';

          // Set the font size and style
          doc.setFontSize(14);
          doc.setFont('helvetica', 'normal');

          // Get the width of the text
          var textWidth =
            (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
            doc.internal.scaleFactor;

          // Calculate the x-coordinate for centering the text
          var xCoordinate = (doc.internal.pageSize.width - textWidth) / 2;

          // Position the text at (xCoordinate, 30)
          doc.text(text, xCoordinate, 30);
        }

        doc.save(this.dataTable?.menuHeading);
      });
    });
  }

  // Export as excel
  exportExcel() {
    let options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    import('xlsx').then((xlsx) => {
      const _data = this.data;
      _data.forEach(function (val, index) {
        const date = new Date(parseInt(val.createdAt));
        val['createdAt'] = date.toLocaleDateString('en', options).toString();
      });
      let aux;
      if (this.filter == 'ticket') {
        aux = _data.map((el) => ({
          createdAt: el.createdAt,
          ticketId: el.ticketId,
          customerName: el.customerName,
          customerLastName: el.customerLastName,
          city: el.city,
          phoneNumber: el.phoneNumber,
          language: el.language,
          dueDate: el.dueDate,
          source: el.source,
          description: el.description,
          actualResponseTime: el.actualResponseTime,
          actualRestorationTime: el.actualRestorationTime,
          actualRestorationtime: el.actualRestorationTime,
          slaRestorationTime: el.slaRestorationTime,
          slaExtendedDuration: el.slaExtendedDuration,
          showSeekApprovel: el.showSeekApprovel,
          sourcetype: el.sourcetype,
          country: el.country,
          clientId: el.clientId,
          enquiryStatus: el.enquiryStatus,
          callContact: el.callContact,
          verbalConsent: el.verbalConsent,
          sendToDP: el.sendToDP,
          channel: el.channel,
          serviceType: el.service_type,
          assignedTo: el.assignedTo,
          currentStatus: el.currentStatus,
          paymentStatus: el.paymentStatus,
          ticketStatus: el.ticketStatus,
        }));
      } else if (this.filter == 'agent') {
        aux = _data.map((el) => ({
          createdAt: el.createdAt,
          serviceName: el.service_name,
          agentName: el.fullname,
          lastLogin: el.lastLogin,
          managerName: el.managerName,
          supervisorName: el.supervisorName,
          mobile: el.mobile,
          designationName: el.designationName,
          vicidialAgentCampaign: el.vicidialAgentCampaign,
          active: el.active,
          call_status: el.call_status,
          loggedIn: el.loggedIn,
          activeStatus: el.activeStatus,
          ticketCnt: el.ticketCnt,
          avgTime: el.avgTime,
          closeCnt: el.close_cnt,
          openCnt: el.open_cnt,
          assignCnt: el.assign_cnt,
          closePer: el.close_per,
        }));
      } else if (this.filter == 'ticket-aging') {
        aux = _data.map((el) => ({
          serviceName: el.service_name,
          ticketCnt: el.ticketCnt,
          avgTime: el.avgTime,
          openCnt: el.open_cnt,
          closeCnt: el.close_cnt,
          closePer: el.close_per,
        }));
      } else if (this.filter == 'call-report') {
        aux = _data.map((el) => ({
          createdAt: el.createdAt,
          ticketId: el.ticket_id,
          userName: el.userName,
          customerName: el.customerName,
          ticketDate: el.ticket_Date,
          callId: el.callId,
          dispositionTime: el.dispositionTime,
          phoneNumber: el.phoneNumber,
          agentId: el.agentId,
          callStartTime: el.callStartTime,
          callEndTime: el.callEndTime,
          Date: el.date,
          seconds: el.seconds,
          callRecordingId: el.callRecordingId,
          callRecordingFileName: el.callRecordingFileName,
          callType: el.callType,
          disposition: el.disposition,
          transferType: el.transferType,
          transferedTo: el.transferedTo,
          user: el.user,
          afterCallHours: el.afterCallHours,
        }));
      } else if (this.filter == 'email-report') {
        aux = _data.map((el) => ({
          createdAt: el.createdAt,
          ticketId: el.ticket_id,
          medium: el.medium,
          subject: el.subject,
          userName: el.userName,
          emailType: el.direction,
          fromId: el.from,
          to: el.to,
        }));
      } else {
        aux = _data;
      }

      const worksheet = xlsx.utils.json_to_sheet(aux);
      var wscols = [
        { wch: 26 },
        { wch: 26 },
        { wch: 30 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      worksheet['!cols'] = wscols;

      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, this.filter);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      let EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      console.log('datadatadatadata', data);
      FileSaver.saveAs(
        data,
        this.dataTable?.menuHeading +
          '_export_' +
          new Date().getTime() +
          EXCEL_EXTENSION
      );
    });
  }

  // filter(value, field, dt) {
  //   console.log(value, field, match)
  //   dt.filter(value.statusText, 'year', 'equal')
  // }

  // dataTable: IDataTable = {
  //   columns: [
  //     { field: 'brand', header: 'brand' },
  //     { field: 'lastYearSale', header: 'lastYearSale' },
  //     { field: 'thisYearSale', header: 'thisYearSale' },
  //     { field: 'lastYearProfit', header: 'lastYearProfit' },
  //     { field: 'thisYearProfit', header: 'thisYearProfit' }
  //   ],
  //   data: [
  //     { brand: 'Apple', lastYearSale: '51%', thisYearSale: '40%', lastYearProfit: '$54,406.00', thisYearProfit: '$43,342' },
  //     { brand: 'Samsung', lastYearSale: '83%', thisYearSale: '96%', lastYearProfit: '$423,132', thisYearProfit: '$312,122' },
  //     { brand: 'Microsoft', lastYearSale: '38%', thisYearSale: '5%', lastYearProfit: '$12,321', thisYearProfit: '$8,500' },
  //     { brand: 'Philips', lastYearSale: '49%', thisYearSale: '22%', lastYearProfit: '$745,232', thisYearProfit: '$650,323,' },
  //     { brand: 'Song', lastYearSale: '17%', thisYearSale: '79%', lastYearProfit: '$643,242', thisYearProfit: '500,332' },
  //     { brand: 'LG', lastYearSale: '52%', thisYearSale: ' 65%', lastYearProfit: '$421,132', thisYearProfit: '$150,005' },
  //     { brand: 'Sharp', lastYearSale: '82%', thisYearSale: '12%', lastYearProfit: '$131,211', thisYearProfit: '$100,214' }
  //   ],
  //   scrollHeight: 100,
  //   rows: 10,
  //   totalRecords: 10,
  //   addButton : false,
  //   editButton : false
  // };

  confirmDelete(event) {
    console.log('confirm delete', event);
    if (event) {
      console.log('delete the row', event);
      this.selectedData('delete', this.selectRow);
    } else {
      console.log('dont delete the row');
    }
    this.selectRow = null;
  }

  deleteEvent(event) {
    this.selectRow = event;
    this.confirmDialog.open({
      message: 'Are you sure, you want to delete the record?',
      rejectLabel: 'No',
      confirmLabel: 'Yes',
      imgSrc: '../../../../assets/images/confirm.svg',
    });
  }
}
