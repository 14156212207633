import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { EModalType, IConfirmDialog } from '../../../models/modules/shared/dialog.model';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  @Output() confirm = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() closeButton = new EventEmitter();

  modalDialog = {
    visible: false,
    type: null,
    message: '',
    imgSrc: '',
    confirmLabel: '',
    rejectLabel: ''
  }

  
  constructor() { }

  ngOnInit(): void {
  }


  open(inputParams: IConfirmDialog) {
    this.modalDialog.visible = true;
    this.modalDialog.type = EModalType.CONFIRM;
    this.modalDialog.imgSrc = inputParams.imgSrc ?? '../../../../assets/images/login-logout.svg';
    this.modalDialog.message = inputParams.message ?? 'Are you sure !';
    this.modalDialog.confirmLabel = inputParams.confirmLabel ?? 'Confirm';
    this.modalDialog.rejectLabel = inputParams.rejectLabel ?? 'Reject';

  }

   // Close modal
   closeModal() {
    this.modalDialog.visible = false;
    this.modalDialog.type = null;
    this.modalDialog.message = '';
    this.close.emit(true);
  }

  closeButtonClick(){
    this.closeButton.emit(true)
  }

  // Confirm
  _confirm() {
    this.closeModal();
    this.confirm.emit(true);
  }
}
