import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class socketService {

    constructor(private socket: Socket) { }
    getMessage(event) {
        return this.socket
            .fromEvent<any>(event)
            .pipe(map(res => res));
    }
    public disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}