import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { finalize } from "rxjs/operators";
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Observable, throwError, BehaviorSubject } from 'rxjs';

// jwt service
import { JwtService } from './jwt.service';

// user service
import { UserService } from './user.service';

// Loader service
import { LoaderService } from './loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { socketService } from './socket.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  constructor(private jwtService: JwtService, private userService: UserService, public loaderService: LoaderService, private router: Router, private socketService: socketService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get token
    const token = this.jwtService.getToken();

    var res = req.url.match(/103.21.34.19/g);
    if (res != null) {

      console.log("Request", req);

      //   Access-Control-Allow-Headers': 'Content-Type',
      // 'Access-Control-Allow-Methods': 'GET',
      // 'Access-Control-Allow-Origin': '*'
      return next.handle(req)
    }

    // token present set authorization header and update user session time
    if (token) {
      // this.userService.updateSessionTime();
      this.loaderService.show();
      return next.handle(
        req.clone({
          headers: req.headers.append('Authorization', 'Bearer ' + token)
        })
      ).pipe(finalize(() => this.loaderService.hide())).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 403) {
          // return this.handle401Error(request, next);

          localStorage.clear();
          this.router.navigate(['/auth'])
          this.socketService.disconnect()
        } else {
          return throwError(error);
        }
      }));;

    }
    return next.handle(req)
  }
}