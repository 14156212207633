<div>
  <!-- <p-toast></p-toast> -->
  <p-accordion >
    <p-accordionTab header="Tasker" [selected]="true">
      <form action="" [formGroup]="createTaskForm">
        <div class="p-grid p-mb-2" style="line-height: 20px;">
          <div class="p-col-12 left-panel">
            <label class="labelCls" style="font-weight: bold;"
              >What type of task are you setting?</label
            >
            <div class="grid" style="padding: 10px;">
              <input
                type="radio"
                formControlName="taskType"
                value="Email"
                id="Email"
              />
              <label for="Email" class="labelCls">Email</label>
              &nbsp;
              <input
                type="radio"
                formControlName="taskType"
                value="Call"
                id="Call"
              />
              <label for="Call" class="labelCls">Call</label>
              &nbsp;
              <input
                type="radio"
                value="Meeting"
                id="Meeting"
                formControlName="taskType"
              />
              <label for="Meeting" class="labelCls">Meeting</label>
              &nbsp;
              <input
                type="radio"
                formControlName="taskType"
                value="SMS"
                id="SMS"
              />
              <label for="SMS" class="labelCls">SMS</label>
              &nbsp;
              <input
                type="radio"
                formControlName="taskType"
                value="Reminder"
                id="Reminder"
              />
              <label for="Reminder" class="labelCls">Reminder</label>
            </div>
            <p-message for="taskType" severity="error" text="Task type is required"
              *ngIf="!createTaskForm.controls['taskType'].valid && submitted">
            </p-message>
        
            <label class="labelCls" style="font-weight: bold;"
              >What priority would you want to assign to the task?</label
            >
            <div class="grid">
              <div style="padding:10px">
              <input
                type="radio"
                formControlName="priority"
                value="Critical"
                id="Critical"
              />
              <label for="Critical" class="labelCls">Critical</label>
              &nbsp;
              <input
                type="radio"
                formControlName="priority"
                value="High"
                id="High"
              />
              <label for="High" class="labelCls">High</label>
              &nbsp;
              <input
                type="radio"
                value="Medium"
                id="Medium"
                formControlName="priority"
              />
              <label for="Medium" class="labelCls">Medium</label>
              &nbsp;
              <input
                type="radio"
                formControlName="priority"
                value="Low"
                id="Low"
              />
              <label for="Low" class="labelCls">Low</label>
              <p-message for="priority" severity="error" text="Priority is required"
              *ngIf="!createTaskForm.controls['priority'].valid && submitted">
            </p-message>
          </div>
            <div style="margin: 3%;"></div>
              <div class="form-section">
                <label class="labelClass">Task Owner</label>
                <span class="ui-fluid">
                  <p-autoComplete
                    styleClass="inputCls"
                    [(ngModel)]="selectedUsers"
                    readonlyInput="true"
                    formControlName="owner"
                    [suggestions]="filteredUsersMultiple"
                    styleClass="wid100"
                    (completeMethod)="searchUserList($event)"
                    [minLength]="1"
                    placeholder="Users"
                    field="fullname"
                    [multiple]="true"
                  >
                  </p-autoComplete>
                  <p-message for="owner" severity="error" text="Users is required"
                *ngIf="!createTaskForm.controls['owner'].valid && submitted">
              </p-message>
                </span>
                <div style="margin: 3%;"></div>
                <label class="labelClass">Task Subject</label>
                <input
                  id="email"
                  type="text"
                  maxlength="50"
                  minlength="0"
                  pInputText
                  class="form-control inputcls"
                  formControlName="subject"
                />
                <p-message for="subject" severity="error" text="Subject is required"
                *ngIf="!createTaskForm.controls['subject'].valid && submitted">
              </p-message>
              <div style="margin: 3%;"></div>
                <label class="labelClass">Task Notes</label>
                <textarea
                  class="form-control inputclsDesc"
                  name=""
                  maxlength="300"
                  [rows]="3" pInputTextarea autoResize="false"
                  formControlName="notes"
                ></textarea>
                <p-message for="notes" severity="error" text="Notes is required"
                *ngIf="!createTaskForm.controls['notes'].valid && submitted">
              </p-message>
            
              </div>
              <div style="margin: 3%;"></div>
              <div >
                <label class="labelClass">Check In Time </label>
                <div>
                  <p-calendar id="timeonly" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" placeholder="Select Time" formControlName="time" value="#{calendarView.date14}" timeOnly="true" ></p-calendar>
                  <div style="margin: 3%"></div>
                  <label class="labelClass">Check In Date</label>
                  <div
                    style="padding-bottom: 0; padding-top: 0;"
                    class="date-picker"
                  >
                    <p-calendar
                      [(ngModel)]="checkDate"
                      (onSelect)="getDate()"
                      [inline]="true"
                      [showWeek]="true"
                      [minDate]="minimumDate"
                      readonlyInput="true"
                      formControlName="date"
                      [style]="{'width':'50%'}" [inputStyle]="{'width':'50%'}" 
                    ></p-calendar>
                  </div>
                </div>
            
              </div>
              <button
                pButton
                type="button"
                *ngIf="!showUpdate"
                style="font-size: 0.75rem; float: right;"
                label="Save"
                class="ui-button outline-primary-button"
                (click)="saveTask()"
              ></button>
              <button
                pButton
                *ngIf="showUpdate"
                type="button"
                style="font-size: 0.75rem; float: right;"
                label="Save Changes"
                class="ui-button outline-custom-button"
                (click)="updateTask()"
              ></button>
            </div>
          </div>
        
        </div>
      </form>
    </p-accordionTab>
  </p-accordion>
  <br />
  <div class="p-col-12 second-section" *ngIf="taskList && taskList.length > 0 || completedList && completedList.length > 0">
    <div *ngFor="let tasks of taskList">
      <h3>{{ tasks['date'] | date: 'EEE, dd MMM yyyy' }}</h3>
    <div class="p-col-12 check-list" *ngFor="let task of tasks['records']">
      <input
        type="checkbox"
        value=""
        id=""
        (click)="checkClicked(task, 'InProgress')"
      />
      <label class="labelCls" style="cursor: pointer; font-size: 0.85rem;" (click)="patchValues(task)" >{{ task['subject'] }}</label>
      <div style="float: right; display: flex;">
        <p class="chip" [ngClass]="{ 'low': task['priority'] == 'Low', 'medium': task['priority'] == 'Medium', 'high': task['priority'] == 'High' }">{{ task['priority'] }}</p>
        &nbsp;&nbsp;
        <p>{{ task['time'] | date: 'EEE, dd MMM yyyy h:mm a' }}</p>
        <!-- &nbsp;&nbsp;
        <p>10:30 AM</p> -->
        &nbsp;&nbsp;
        <!-- <input class="star" type="checkbox" title="" /> -->
        <i class="fas fa-phone-volume" *ngIf="task['taskType'] == 'Call'"></i>
        <i class="fas fa-envelope-open-text" *ngIf="task['taskType'] == 'Email'"></i>
        <i class="fas fas fa-handshake" *ngIf="task['taskType'] == 'Meeting'"></i>
        <i class="fas fas fa-sms" *ngIf="task['taskType'] == 'SMS'"></i>
        <i class="fas fa-stopwatch" *ngIf="task['taskType'] == 'Reminder'"></i>
      </div>
    </div>
    </div>
    <h3 *ngIf="completedList && completedList.length > 0" style="color: #007AD9;">Done &nbsp;&nbsp;<span class="done-chip">{{ completedList.length }}</span></h3>
    <div class="p-col-12 check-list" *ngFor="let task of completedList">
      <input
        type="checkbox"
        value=""
        checked
        id=""
        (click)="checkClicked(task, 'Completed')"
      />
      <label class="labelCls" style="cursor: pointer; font-size: 0.85rem; color: #797979; text-decoration: line-through;" (click)="patchValues(task)" >{{ task['subject'] }}</label>
      <div style="float: right; display: flex;">
        <p class="chip" [ngClass]="{ 'low': task['priority'] == 'Low', 'medium': task['priority'] == 'Medium', 'high': task['priority'] == 'High' }">{{ task['priority'] }}</p>
        &nbsp;&nbsp;
        <p>{{ task['time'] | date: 'EEE, dd MMM yyyy h:mm a' }}</p>
        <!-- &nbsp;&nbsp;
        <p>10:30 AM</p> -->
        &nbsp;&nbsp;
        <!-- <input class="star" type="checkbox" title="" /> -->
        <i class="fas fa-phone-volume" *ngIf="task['taskType'] == 'Call'"></i>
        <i class="fas fa-envelope-open-text" *ngIf="task['taskType'] == 'Email'"></i>
        <i class="fas fas fa-handshake" *ngIf="task['taskType'] == 'Meeting'"></i>
        <i class="fas fas fa-sms" *ngIf="task['taskType'] == 'SMS'"></i>
        <i class="fas fa-stopwatch" *ngIf="task['taskType'] == 'Reminder'"></i>
      </div>
    </div>
  </div>
</div>
<message-modal #messageModal></message-modal>