export interface IPriceData {
    ticket_id: string,
    created_at: string,
    invoice_no: string,
    description: string,
    price: number,
    discount: number,
    billed_amount: number,
    tax_description?: string,
    tax_amount?: number,
    paid_amount?: string,
    notes?: string,

    // User extra
    type?: string,
    customer?: {
        name: string,
        email: string,
        contact: string
    },
    expire_by?: string, // remove
    projectCode?: string, // remove
    callback_url?: string, // remove
    callback_method?: string // remove

    // Customer extra
    id?: number
    rzr_customer_id?: string,
    rzr_invoice_id?: string,
    rzr_order_id?: string,
    expire_at?: string,
    issue_at?: string,
    paid_at?: string,
    amount?: string,
    cancelled_at?: string,
    short_url?: string,
    payment_status?: string,
    invoice_receipt?: string,
    rzr_signature?: string,
    created_by?: string,
    rzr_payment_id?: string,
    invoice_url?: string,
    
    ticket_DB_id?: string
}

export enum EPaymentUserType {
    'USER' = 'USER',
    'CUSTOMER' = 'CUSTOMER'
}


export interface IPaymentInput {
    type: EPaymentUserType,
    ticket_id: string,
    invoice?: string,
    id?: string
}