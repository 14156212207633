import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { ApiService } from '../../../services/api.service';
import { Subscription } from 'rxjs';
import { IDialog, EDialogSize } from '../../../models/modules/shared/dialog.model';
import { environment } from '../../../../environments/environment';
import { AssetsComponent } from '../assets/assets.component';

@Component({
  selector: 'sidebar-navigation',
  templateUrl: './sidebar-navigation.component.html',
  styleUrls: ['./sidebar-navigation.component.css'],
})
export class SidebarNavigationComponent implements OnInit {

  @Output() logout = new EventEmitter();
  @ViewChild("assetsComponent") assetsComponent: AssetsComponent;
  isFloatMenuVisible = false;
  isArrowRotated = false;

  getUserDetails$: Subscription;
  getCount$: Subscription;
  getTicketStatusList$: Subscription;
  assetViewed: boolean = false
  taskViewed: boolean = false

  viewDialog: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.MEDIUM_1
  }

  taskDialog: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.SMALL_1
  }

  //Calendar Dialogue
  calendarModalDialogue: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.LARGE
  }

  assetsDialogue: IDialog = {
    display: false,
    showHeader: false,
    showFooterButtons: false,
    showBackDrop: true,
    showCustomCloseButton: true,
    size: EDialogSize.LARGE_1
  }
  viewProfileData = null;
  image_url;
  userConfig;
  _session;

  // ticketStatusCount;
  statusList;
  ticketStatusCount = [
    { key: 'new', colorCode: "#1289a6", value: 0, label: 'New' },
    // {key: 'pending', colorCode: "#ff7675", value: 0, label: 'Pending'}, 
    { key: 'assigned', colorCode: "#218c74", value: 0, label: 'Assigned' },
    { key: 'approval', colorCode: "#D02F6B", value: 0, label: 'Approval' },
    { key: 'closed', colorCode: "#636e72", value: 0, label: 'Closed' }
  ]

  placeholderImageUrl: string = '../../../../assets/images/photo.png';

  constructor(
    private router: Router,
    private userService: UserService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this._session = this.userService.getSession();
    console.log("this._session==", this._session);
    this.userConfig = this._session['userConfig'];
    console.log("profile::", this._session.profile_image);
    if (this._session.profile_image) {
      let url = environment.api_url + this._session.profile_image.url.substring(1)
      url = url.replace(/([^:]\/)\/+/g, "$1")
      this.image_url = url;
    } else {
      this.image_url = this.placeholderImageUrl;
    }
    console.log(this.image_url);
    console.log("TKCOUNT")
    // this.getTicketStatusList();
    this.getTotalCount()
  }


  toggleFloatMenu() {
    this.isFloatMenuVisible = !this.isFloatMenuVisible;
    this.isArrowRotated = !this.isArrowRotated;
  }

  onselectMenu(menu, submenu?) {
    if (submenu) document.getElementById(submenu).classList.toggle('show');

    let x = document.getElementById('SideNav').querySelectorAll("a, button");
    x.forEach(_x => _x.classList.remove('active'));
    document.getElementById(menu).classList.toggle('active');
  }

  navigate(navigationLink) {
    this.router.navigate([navigationLink])
  }

  openAssets() {
    this.assetsDialogue.display = true
    this.assetViewed = true
  }

  openTasks() {
    this.taskDialog.display = true;
    this.taskViewed = true;
  }

  getUserDetails() {
    this.getUserDetails$ = this.apiService.get(`get-user-by-id/${this._session['id']}`).subscribe((response) => {
      console.log(response);
      if (!response.success) return;

      this.viewProfileData = response.data;

      console.log(this.viewProfileData)
      this.viewDialog.display = true;

    });
  }


  getTotalCount() {
    this.getCount$ = this.apiService.get('get-tickets-count').subscribe((e) => {
      if (e.status && e.data) {
        let _ticketStatusCount = e.data

        this.ticketStatusCount = this.ticketStatusCount.map(x => {
          x.value = _ticketStatusCount[x.key];
          return x;
        });
        console.log('TKCOUNT', this.ticketStatusCount)

      } else {
        return
      }
    })
  }

  // Get the ticket status list
  getTicketStatusList() {
    this.getTicketStatusList$ = this.apiService.get(`get-ticket-status-master?type=dashboard`).subscribe((response) => {
      const _statusList = response.data;
      console.log('getTicketStatusList', _statusList)
      this.ticketStatusCount = this.ticketStatusCount.map(x => {
        console
        let _found = _statusList.find(y => y.status.toUpperCase() == x.key.toUpperCase())
        x.colorCode = _found['colorCode'];
        return x;
      });
      console.log('ticketStatusCount', this.ticketStatusCount)

    })
  }

  getStatusColor(status) {
    if (this.statusList.length < 1) return;
    const found = this.statusList.find(x => x.status.toUpperCase() == status.toUpperCase());
    console.log()
    return (found) ? found['colorCode'] : '#337AB7';
  }

  closeViewProfile(e?) {
    this.viewDialog.display = false;
    this.viewProfileData = null
    this.assetViewed = false
    this.taskViewed = false
  }

  handleImageError(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.placeholderImageUrl;
  }

  _logout() {
    this.logout.emit(true);
  }



  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.getUserDetails$) this.getUserDetails$.unsubscribe();
    if (this.getCount$) this.getCount$.unsubscribe()
  }

  openCalendar() {
    console.log("open")
    this.calendarModalDialogue.display = true
  }

}
