import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SelectItem } from 'primeng/api';
import { SelectItemGroup } from 'primeng/api';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CalendarModule } from 'primeng/primeng';
import { ApiService } from 'src/app/services/api.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
})
export class TasksComponent implements OnInit {
  statusFilterList: any;
  checkDate: any;
  Users: any[];
  selectedUsers: any[];
  filteredUsersSingle: any[];
  filteredUsersMultiple: any[];
  statusList: any;
  selectedTask: any;
  taskList: any;
  submitted: Boolean = false;
  showUpdate: Boolean = false;
  ticketDetail: any;
  completedList: any;
  initDate: any;
  minimumDate = new Date()

  createTaskForm: FormGroup;

  @ViewChild("messageModal") messageModal: ModalComponent;
  
  @Output() submitTaskDetails = new EventEmitter<Object>();
  @Output() updateTaskDetails = new EventEmitter<Object>();
  @Input() receivedTicketDetails: any;

  constructor(private apiService: ApiService, private modal: ModalComponent) {
    this.createTaskForm = new FormGroup({
      taskType: new FormControl(null, Validators.required),
      priority: new FormControl(null, Validators.required),
      owner: new FormControl(null, Validators.required),
      subject: new FormControl(null, Validators.required),
      notes: new FormControl(null, Validators.required),
      // status: new FormControl(null, Validators.required),
      date: new FormControl(null),
      time: new FormControl(null),
      ticket: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.statusFilterList = [{ status: 'Test' }, { status: 'test 1' }];
    this.getStatusList();
    this.getUsersList();
    this.initDate = new Date()
    this.getTaskDetails();

    let d = new Date()
    let hours = d.getHours()
    let minutes = d.getMinutes()
    this.createTaskForm.get('time').setValue(`${hours}:${minutes}`)
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['receivedTicketDetails']) {
      console.warn("RSD", changes['receivedTicketDetails']);
      this.initDate = new Date()
      this.ticketDetail = changes['receivedTicketDetails']['currentValue']
      if (this.ticketDetail) this.getTaskDetails();
    }
  }

  filterUsersMultiple(event) {
    let query = event.query;
    // this.Users.filter(countries => {
    this.filteredUsersMultiple = this.filterCountry(query, this.Users);
    // });
  }

  filterCountry(query, countries: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < countries.length; i++) {
      let country = countries[i];
      if (country.fullname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }
    return filtered;
  }

  getDate() {
    console.warn(this.checkDate);
    this.initDate = this.checkDate;
    this.getTaskDetails()
  }

  getStatusList() {
    this.apiService.get('get-task-status-master').subscribe(
      (response) => {
        console.log('RES::', response);
        if (response) {
          this.statusList = response['data'];
        } else {
          // this.messageModal.errorDialog(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getUsersList() {
    this.apiService.get('get-user').subscribe(
      (response) => {
        if (response.success) {
          this.Users = response['data'];
        } else {
          // this.messageModal.errorDialog(response.message);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async searchUserList(event){
      let value = {
        searchText : event.query
      }
      let response = await this.apiService.post('search-user',value).toPromise()
      this.filteredUsersMultiple = response.data
  }

  patchValues(task) {
    this.selectedTask = task;
    this.createTaskForm.get('subject').setValue(task['subject'])
    this.createTaskForm.get('notes').setValue(task['notes'])
    this.createTaskForm.get('taskType').setValue(task['taskType'])
    this.createTaskForm.get('priority').setValue(task['priority'])
    // this.createTaskForm.get('status').setValue(task['status'])
    this.createTaskForm.get('owner').setValue(task['owner'])
    this.createTaskForm.get('date').setValue(new Date(parseFloat(task['date'])))
    this.checkDate = new Date(parseFloat(task['date']))
    this.showUpdate = true;
    let h = parseFloat(task['time'])
    let d = new Date(h)
    let hours = d.getHours()
    let minutes = d.getMinutes()
    this.createTaskForm.get('time').setValue(`${hours}:${minutes}`)
  }

  saveTask() {
    this.submitted = true;
    console.warn(this.createTaskForm);
    if (this.createTaskForm.valid) {
      if (!this.checkDate) this.checkDate = new Date()
      if (this.checkDate) {
        let date = this.checkDate.getTime()
        let changeToDate = new Date(date);
        this.createTaskForm.value['date'] = changeToDate.setHours(0,0,0,0);
        if (Date.parse(this.createTaskForm.value['time'])) {
          this.createTaskForm.value['time'] = this.createTaskForm.value['time'].getTime()
        } else {
          this.createTaskForm.value['time'] = new Date().getTime();
        }
      } else {
        let wDate = new Date()
        let wTime = new Date()
        this.createTaskForm.value['date'] = wDate.setHours(0,0,0,0)
        this.createTaskForm.value['time'] = wTime.getTime()
      }
      // this.createTaskForm.value['status'] = this.createTaskForm.value['status'][
      //   'id'
      // ];
      let usersList = [];
      for (const user of this.createTaskForm.value['owner']) {
        usersList.push(user['id']);
      }
      this.createTaskForm.value['owner'] = usersList;
      console.warn(this.createTaskForm.value, this.checkDate);
      this.submitTheTaskDetails(this.createTaskForm.value)
    }
  }

  updateTask() {
    this.submitted = true;
    console.warn(this.createTaskForm.value);
    
    if (this.createTaskForm.valid) {
      if (this.checkDate) {
        let date = this.checkDate.getTime()
        let changeToDate = new Date(date);
        this.createTaskForm.value['date'] = changeToDate.setHours(0,0,0,0);
        if (Date.parse(this.createTaskForm.value['time'])) {
          this.createTaskForm.value['time'] = this.createTaskForm.value['time'].getTime()
        } else {
          this.createTaskForm.value['time'] = this.selectedTask['time']
        }
      } else {
        let wDate = new Date()
        let wTime = new Date()
        this.createTaskForm.value['date'] = wDate.setHours(0,0,0,0)
        if (this.createTaskForm.value['time']) {
          this.createTaskForm.value['time'] = this.createTaskForm.value['time'].getTime()
        } else {
          this.createTaskForm.value['time'] = this.selectedTask['time']
        }
      }
      // this.createTaskForm.value['status'] = this.createTaskForm.value['status'][
      //   'id'
      // ];
      this.createTaskForm.value['id'] = this.selectedTask['id']
      let usersList = [];
      for (const user of this.createTaskForm.value['owner']) {
        usersList.push(user['id']);
      }
      this.createTaskForm.value['owner'] = usersList;
      console.warn(this.createTaskForm.value);
      this.updateTheTaskDetails(this.createTaskForm.value)
    }
  }

  submitTheTaskDetails(event) {
    if (this.ticketDetail && this.ticketDetail['id']) event['ticket'] = this.ticketDetail['id']
    console.warn(event);
    // time to be set 0 milliseconds for cron 
    let time = new Date(event.time)
    time.setMilliseconds(0)
    event['time'] = time.getTime()
    this.apiService.post('create-task', event).subscribe(
      response => {
        console.log("RES::",response);
        if (response.status) {
          console.log(response.message); 
          this.submitted = false; 
          this.messageModal.successDialog(response.message);
          //reset
          this.createTaskForm.reset()
          let d = new Date()
          let hours = d.getHours()
          let minutes = d.getMinutes()
          this.createTaskForm.get('time').setValue(`${hours}:${minutes}`)
          this.getTaskDetails()
        } else {
          this.messageModal.errorDialog(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  getTaskDetails() {
    if (this.ticketDetail && this.ticketDetail['id'] && this.initDate) {
      var url = `get-task?ticketId=${this.ticketDetail['id']}&date=${this.initDate.getTime()}`
    } else {
      var url = `get-task?date=${this.initDate.getTime()}`
    }
    this.apiService.get(url).subscribe(
      response => {
        console.log("RES::",response);
        if (response.status) {
          console.log(response.message);  
          this.taskList = response['inprogress']
          this.completedList = response['completed']
          // this.messageModal.successDialog(response.message);
        } else {
          this.messageModal.errorDialog(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  updateTheTaskDetails(event) {
    if (this.ticketDetail && this.ticketDetail['id']) event['ticket'] = this.ticketDetail['id']
    console.warn(event);
    let time = new Date(event.time)
    time.setMilliseconds(0)
    event['time'] = time.getTime()
    this.apiService.put('update-task', event).subscribe(
      response => {
        console.log("RES::",response);
        if (response.status) {
          console.log(response.message); 
          this.submitted = false;
          this.getTaskDetails()
          this.messageModal.successDialog(response.message);
          this.createTaskForm.reset()
          let d = new Date()
          let hours = d.getHours()
          let minutes = d.getMinutes()
          this.createTaskForm.get('time').setValue(`${hours}:${minutes}`)
          this.showUpdate = false;
        } else {
          this.messageModal.errorDialog(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  checkClicked(task, type) {
    let _body = {
      id: task['id']
    }
    if (type == 'InProgress') _body['check'] = true;
    else _body['check'] = false;
    this.apiService.put('update-task-status', _body).subscribe(
      response => {
        console.log("RES::",response);
        if (response.status) {
          console.log(response.message);
          this.getTaskDetails()
          // this.messageModal.successDialog(response.message);
        } else {
          this.messageModal.errorDialog(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }
}
