// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api_url: 'http://localhost:3333/',
  api_url: 'https://api-kgdesk.wait-wise.com/',
  // api_url: 'https://api-kgdesk.wait-wise.com/',
  //api_url: 'https://api-dev-helpdesk.wait-wise.com/',
  // api_url: 'http://172.20.6.160:3232/',
  // api_url: 'http://172.20.6.151:3231/',
  // api_url_file: 'http://172.20.6.160:3232',
   api_url_file: 'https://api-kgdesk.wait-wise.com/',
  // api_url_file: 'http://172.20.6.151:3232',
  // api_url: 'https://api-kgdesk.wait-wise.com/',
  // api_url: 'https://helpdesk-api.wait-wise.com/',
  // api_url: 'https://api-helpdesk.tanya.care/',
  // socket_url: 'https://api.tanya.wait-wise.com',
  socket_url: 'https://api-kgdesk.wait-wise.com',
  // socket_url: 'http://172.20.6.151:3231',

  api_img_url: 'https://api.tanya.wait-wise.com',
  payment_url: 'http://172.20.8.210:4002',
  ui_base_url: 'http://172.20.8.216:4200/',
  session_time_limit: 20,
  appointment_base_url: 'http://172.20.8.210:4300/',
  // appointment_api_url: 'https://doc-api.wait-wise.com/',
  appointment_api_url: 'http://172.20.8.210:8081/',
  hash_url: 'http://172.20.8.210:4000/api/',
  // vicidial_url: 'http://172.20.8.54/agc/helpdesk/vicidial.php',
  // vicidial_api_url: 'http://172.20.8.54/agc/api.php',
  vicidial_url: 'https://172.16.16.12/agc/helpdesk/vicidial.php',
  vicidial_api_url: 'https://172.16.16.12/agc/api.php',
  shorten_url_live: 'https://url.wait-wise.com/',
  shorten_url_local: 'http://172.20.8.210:5000/',
  // calendar_url: 'https://calendar.wait-wise.com/'
  calendar_url: 'http://localhost:4200/',
  client: 'kgis',

  // //
  // vicidial_url: 'http://103.21.34.19/agc/helpdesk/vicidial.php',
  // vicidial_api_url: 'http://103.21.34.19/agc/api.php',
  // };
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

