import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation, Output, EventEmitter, ElementRef } from '@angular/core';

// Models
import { IDialog, EDialogSize } from '../../../models/modules/shared/dialog.model';

@Component({
  selector: 'common-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {

  @Input() dialogConfig: IDialog;
  @Input() confirmCall: boolean;


  @Output() closeModal = new EventEmitter();

  // Default style

  _style = {
    'height': 'auto',
    'width': '30vw',
    'border-radius': '5px',
  }
  public refSizeType = EDialogSize;
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dialogConfig'] != undefined) {
      this.updateStyle(changes['dialogConfig'].currentValue['size'])
      console.log("OVERFLOW", changes['dialogConfig'].currentValue['hideOverflowY'])
      if (changes['dialogConfig'].currentValue['hideOverflowY']) {
        this.elementRef.nativeElement.style.setProperty('--overflow-y', 'hidden');
      } else {
        this.elementRef.nativeElement.style.setProperty('--overflow-y', 'auto');
      }
    }
  }


  updateStyle(size) {
    let _ww = window.innerWidth;
    console.log('_ww', _ww)
    if (size == EDialogSize.LARGE) {
      // this._style.height = 'auto';
      this._style.width = _ww <= 1280 ? '95%' : '80%';
    }
    if (size == EDialogSize.LARGE_1) {
      // this._style.height = 'auto';
      this._style.width = _ww <= 1280 ? '85%' : '70%';
    }
    if (size == EDialogSize.MEDIUM) {
      // this._style.height = '70vh';
      this._style.width = _ww <= 1280 ? '90%' : '50%';
    }
    if (size == EDialogSize.MEDIUM_1) {
      // this._style.height = '70vh';
      this._style.width = _ww <= 1280 ? '70%' : '55%';
    }
    if (size == EDialogSize.SMALL) {
      // this._style.height = '50vh';
      this._style.width = _ww <= 1280 ? '45%' : '30%';
    }
    if (size == EDialogSize.SMALL_1) {
      // this._style.height = '50vh';
      this._style.width = _ww <= 1280 ? '30%' : '40%';
    }
  }


  closeDialog() {
    console.log("confirm call --- ", this.confirmCall)
    if (!this.confirmCall) {
      this.dialogConfig.display = false;
    }
    this.closeModal.emit(true);
  }


  closeClickDialog() {
    this.closeModal.emit(true);
  }


}
