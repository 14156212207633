<p-panel styleClass="customPanel customPanelTitle" [toggleable]="(panelConfig.toggleable) ? true : false"
  [collapsed]="(panelConfig.collapsed) ? true : false"
  [expandIcon]="(panelConfig.expandIcon) ? panelConfig.expandIcon : 'pi pi-plus'"
  [collapseIcon]="(panelConfig.collapseIcon) ? panelConfig.collapseIcon : 'pi pi-minus'"
  [toggler]="(panelConfig.toggler) ? panelConfig.toggler : 'icon'">
  <p-header [style]="{'background-color':panelConfig.headerColor}" *ngIf="panelConfig.showHeader">
    <ng-content select="[panelHeader]"></ng-content>
  </p-header>

  <ng-content select="[panelBody]"></ng-content>

  <p-footer [style]="{'background-color':panelConfig.headerColor}" *ngIf="panelConfig.showFooter">
    <ng-content select="[panelFooter]"></ng-content>
  </p-footer>

</p-panel>
