import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UpdateTimelineComponent } from './modules/dashboard/update-timeline/update-timeline.component';
import { IntakeFormComponent } from './modules/shared/forms/intake-form/intake-form.component';
import { ViewPaymentComponent } from './modules/shared/view-payment/view-payment.component';
import { DoctorDetailComponent } from "./modules/vendor/doctor-detail/doctor-detail.component";
import { ViewTicketComponent } from './modules/shared/view-ticket/view-ticket.component';


const routes: Routes = [

  { path: '', redirectTo: '/dashboard', pathMatch: 'full'},

  {
    path: 'auth', loadChildren: () =>
      import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule), data: { standalone: true }
  },
  { path: 'update-timeline', component: UpdateTimelineComponent, data: { standalone: true } },
  { path: 'view-payment', component: ViewPaymentComponent, data: { standalone: true } },
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), data: {showCreateButton: true} },
  { path: 'charts', loadChildren: () => import('./modules/charts/charts.module').then(m => m.ChartsModule) },
  { path: 'user', loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule) },
  { path: 'sla', loadChildren: () => import('./modules/sla-matrix/sla-matrix.module').then(m => m.SlaMatrixModule) },
  { path: 'sla-config', loadChildren: () => import('./modules/sla-config/sla-config.module').then(m => m.SlaConfigModule) },
  { path: 'support-group', loadChildren: () => import('./modules/support-group/support-group.module').then(m => m.SupportGroupModule) },
  { path: 'department', loadChildren: () => import('./modules/department/department.module').then(m => m.DepartmentModule) },
  { path: 'role', loadChildren: () => import('./modules/role/role.module').then(m => m.RoleModule) },
  {path : 'support-level',loadChildren : ()=> import('./modules/support-level/support-level.module').then(m => m.SupportLevelModule)},
  { path: 'service-type', loadChildren: () => import('./modules/service-type/service-type.module').then(m => m.ServiceTypeModule) },
  // { path: 'appointment', loadChildren: () => import('./modules/appointment/appointment.module').then(m => m.AppointmentModule) },
  { path: 'report', loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule) },
  { path: 'vendor', loadChildren: () => import('./modules/vendor/vendor.module').then(m => m.VendorModule),data: { standalone: true } },
  { path: 'forms/intake-form', component: IntakeFormComponent, data: { standalone: true } },
  { path: 'asset-type', loadChildren: () => import('./modules/asset-type/asset-type.module').then(m => m.AssetTypeModule) },
  { path: 'incident', loadChildren: () => import('./modules/incidents/incidents.module').then(m => m.IncidentModule) },

  { path: 'knowledge-base', loadChildren: () => import('./modules/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule) },
  { path: 'standalone/knowledge-base', loadChildren: () => import('./modules/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule), data: { standalone: true } },
  // { path: '**', redirectTo: 'dashboard', pathMatch: 'prefix' },
  { path: 'problems', loadChildren: () => import('./modules/problems/problems.module').then(m => m.ProblemsModule) },
  { path: 'organisation', loadChildren: () => import('./modules/organisation/organisation.module').then(m => m.OrganisationModule) },
  { path: 'contact-info', loadChildren: () => import('./modules/contact-info/contact-info.module').then(m => m.ContactInfoModule) },
  { path: 'dashboard/ticket-details', loadChildren: () => import('./modules/shared/view-ticket/view-ticket.module').then(m => m.ViewTicketModule),data: { standalone: false } },
  { path: 'frequently-asked', loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule)},
  { path: 'announcements', loadChildren: () => import('./modules/announcements/announcements.module').then(m => m.AnnouncementsModule)},

  { path: '**', redirectTo: 'auth', pathMatch: 'prefix' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
