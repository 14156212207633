<div class="p-grid user-container">
    <div class="p-col-6">
      <panel [panelConfig]="viewPanel1" Class="customPanelHeader" >
            <div panelHeader >
                <span>Exisiting Assets</span>
            </div>
            <div panelBody>
                <div class="p-grid" style="align-items: baseline;">
                    <div class="p-col-3 assets-heading" >
                        <span>Service Type</span>
                    </div>
                    <div class="p-col-6">
                        <p-dropdown  [options]="serviceList" placeholder="Select Service Type"
                      appendTo="body" optionLabel="name" [(ngModel)]="selectedService" styleClass="contact-drop"></p-dropdown>
                    </div>
                    <div class="p-col-3" style="margin-top: 8px;">
                        <button pButton pRipple type="button" style="background-color: white;color: black;" (click)="showService()" label="Show Assets"></button>
                    </div>
                </div>
                <div class="p-grid p-mt-2 p-5">
                    <div class="p-col-12 assets">
                        Assets
                    </div>
                </div>
                <div class="p-grid p-mt-1 p-10">
                    <div class="p-col-12 form-list">
                        <div *ngFor="let item of currentList">
                            <div class="p-field-checkbox">
                              <p-checkbox class="form-check" [(ngModel)]="item.isChecked" [binary]="true" name="group1"></p-checkbox>
                              <label for="ny">{{item.form_name}}</label><span
                                class="select {{(item.id==selectedAssets.id)?'active':''}}" (click)="selectAssets(item)">...</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-grid p-mt-1 p-10">
                    <div class="p-col-12" style="text-align: end;">
                        <button pButton pRipple type="button" (click)="updateAssetsToService()" label="Add Asset to Service"></button>
                    </div>
                </div>
                <div class="p-grid p-mt-5 p-10">
                    <div class="p-col-12" style="border: 1px solid;">
                        <div class="notes">Note:</div>
                        <div class="note-list">Add a form/doc/Audio from here</div>
                        <div class="note-list">Add it to a service while you are setting up the Service Type</div>
                        <div class="note-list">Multiple forms/assets can be added to a Service Type</div>
                        <div class="note-list">The same assets can be added to different Service Types</div>
                    </div>
                </div>
            </div>
        </panel>
    </div>
    <div class="p-col-6">
        <panel [panelConfig]="viewPanel1">
              <div panelHeader>
                  <span>{{(currentStatus=='add')?'Add New Asset':'Edit Asset'}}</span>
              </div>
              <div panelBody>
                  <form [formGroup]="assetForm" >
                  <div class="p-grid p-5">
                      <div class="p-col-12">
                          <div class="question">What type of document are you uploading</div>
                          <div class="p-grid p-mt-1">
                              <div class="p-col">
                                <div class="p-field-radiobutton">
                                    <p-radioButton name="city" (click)="setFormName('Form Name')" formControlName="form_type" value="Form" inputId="city1"></p-radioButton>
                                    <label for="city1">Form</label>
                                </div>
                              </div>
                              <div class="p-col">
                                <div class="p-field-radiobutton">
                                    <p-radioButton name="city" (click)="setFormName('Document Name')" value="Document" formControlName="form_type" inputId="city1"></p-radioButton>
                                    <label for="city1">Document</label>
                                </div>
                              </div>
                              <div class="p-col">
                                <div class="p-field-radiobutton">
                                    <p-radioButton name="city" (click)="setFormName('Audio Name')" value="Audio" formControlName="form_type" inputId="city1"></p-radioButton>
                                    <label for="city1">Audio</label>
                                </div>
                              </div>
                              <div class="p-col">
                                <div class="p-field-radiobutton">
                                    <p-radioButton name="city" (click)="setFormName('Video Name')" value="Video" formControlName="form_type" inputId="city1"></p-radioButton>
                                    <label for="city1">Video</label>
                                </div>
                              </div>
                              <div class="p-col">
                                <div class="p-field-radiobutton">
                                    <p-radioButton name="city" value="Image" (click)="setFormName('Image Name')" formControlName="form_type" inputId="city1"></p-radioButton>
                                    <label for="city1">Image</label>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="p-grid p-10">
                      <div class="p-col-12">
                            <div class="p-field">
                                <label class="input-lable required-field" for="form-name">Form Name</label>
                                <input id="form-name" style="width: 100%;" formControlName="form_name" type="username" aria-describedby="form-name-help" pInputText maxlength="20"  placeholder="e.g Patient Information Form" />
                            </div>
                            <div class="p-field">
                                <label class="input-lable" style="margin-bottom: 15px;" for="form-link">Upload File / link to form</label>
                                <div class="p-grid">
                                    <input id="form-link" style="margin-left: 8px;" class="upload-input" formControlName="document" type="userlink" aria-describedby="form-link-help" pInputText  />
                                <div class="upload-btn-wrapper">
                                    <button class="btn">Upload a file</button>
                                    <input type="file" #createFile name="myfile" (change)="onFileChange($event)" />
                                  </div>
                                </div>
                            </div>
                            <div class="p-field">
                                <label class="input-lable" for="form-share-link">Shareable Link</label>
                                <input id="form-share-link" style="width: 100%;" formControlName="sharable_link" type="usershare-link" aria-describedby="form-share-link-help" pInputText />
                            </div>
                            <div class="p-field">
                                <label class="input-lable required-field" for="form-description">Description</label>
                                <textarea id="form-description" style="width: 100%;" formControlName="description" [rows]="3" pInputTextarea autoResize="false" placeholder="e.g. This Form is to register patient related information like age/gender and medical condition. Using the shareable link both agent and patient should be able to update this form"></textarea>
                            </div>
                            <div class="p-field" style="margin-top: 10px;">
                                <label class="input-lable required-field" for="form-instruction">Instruction</label>
                                <textarea id="form-instruction" style="width: 100%;" formControlName="instruction" [rows]="6" pInputTextarea autoResize="false" placeholder="1. Do not enter any credit card information or billing info into this.&#10;2. If the patient is uncomfortable sharing any info do not compel.&#10;3. This is mandatory form to be filled before the Doctors are scheduled.&#10;4. Do not Schedule Doctor visits without this form filled."></textarea>
                            </div>
                      </div>
                  </div>
                  <div class="p-grid p-mt-1" style="margin-top: 10px;">
                      <div class="p-col-12" style="text-align: end;">
                            <button pButton pRipple type="button" [disabled]="!assetForm.valid" (click)="updateAssets()" *ngIf="currentStatus=='edit'" label="Update Assets"></button>
                            <button pButton pRipple type="button" [disabled]="!assetForm.valid"  (click)="addAssets()" *ngIf="currentStatus=='add'" label="Add New Asset"></button>
                      </div>
                  </div>
                    </form>
              </div>
          </panel>
      </div>
</div>

<message-modal #messageModal></message-modal>
