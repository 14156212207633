export interface IDialog {
    display: boolean,
    showHeader: boolean,
    headerLabel?: string,
    showFooterButtons: boolean,
    rejectButton?: boolean,
    rejectButtonLabel?: string,
    confirmButton?: boolean,
    confirmButtonLabel?: string,
    closeButton?: boolean,
    closeButtonLabel?: string,
    showCloseButton?: boolean,
    showBackDrop?: boolean,
    draggable?: boolean,
    resizable?: boolean,
    keepInViewport?: boolean,
    blockScroll?: boolean,
    closeOnEscape?: boolean,
    maximizable?: boolean,
    showCustomCloseButton?: boolean,
    showColFilters?: boolean,
    size: EDialogSize | any,
    hideOverflowY? : boolean
}

export enum EDialogSize {
    'LARGE' = 'LARGE',
    'LARGE_1' = 'LARGE_1',
    'MEDIUM' = 'MEDIUM',
    'MEDIUM_1' = 'MEDIUM_1',
    'SMALL' = 'SMALL',
    'SMALL_1' = 'SMALL_1'
}


export enum EModalType {
    'SUCCESS' = 'SUCCESS',
    'ERROR' = 'ERROR',
    'INFO' = 'INFO',
    'WARNING' = 'WARNING',
    'CONFIRM' = 'CONFIRM'
}


export interface IConfirmDialog {
    message?: string,
    confirmLabel?: string,
    rejectLabel?: string,
    imgSrc?: string
}