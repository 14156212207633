import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { JwtService } from 'src/app/services/jwt.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  calendarURL;

  constructor(private sanitizer: DomSanitizer, private userSesson: UserService, private jwtService: JwtService) {

    const session = this.jwtService.getToken()
    this.calendarURL = this.sanitizer.bypassSecurityTrustResourceUrl(environment.calendar_url + 'auth/login' + '?token=' + session)
    console.log('URL', this.calendarURL)
  }

  ngOnInit(): void {
  }

}
