<!--<p>lead-profile works!----</p>-->

<div style="overflow:hidden;">

    <!--<p class="pull-left">
        Service Type&nbsp;&nbsp;&nbsp;
        <select id="drpdwn_service" ng-model="drpdwn_service" ng-init="drpdwn_service ='Select Service type'" required="required">
            <option>Select Service type</option>
            <option>Home Care</option>
            <option>Home Nurse</option>
            <option>Nursing Home</option>
            <option>Home Call Doctor</option>
            <option>Medical Escort</option>
            <option>Medicine Management</option>
            <option>Home Rehabilitation</option>
            <option>Physiotherapy</option>
            <option>Dietitian</option>
            <option>Counselling</option>
            <option>Estate Planning</option>
            <option>Financial Planning</option>
            <option>Others</option>
        </select>
        </p>-->
        <div class="leadborder">
            <div class="form-group">
                <div style="margin-left:25px;">
                    <table style="width: 100%;">
                        <tr>
                            <td style="width: 85%;">
                                <strong style="font-weight: 700; font-size: 18px;color: black;">LEAD PROFILE</strong>
                            </td>
                            <td style="text-align:left;width: 15%;">
                                <span>ISSUED DATE</span><br/>
                                <strong style="font-weight: bold; font-size: 15px;color: black;">21 July 2020</strong>
                            </td>
                        </tr>
                    </table>
                    <hr style="border: 1px solid #dddddd;margin-left: 1px;" />
                    <table>
                        <tr>
                            <td style="width: 15%;">
                                <span class="dimgrey">YOUR PROFILE</span><br />
                                    <span style="font-weight: 700; font-size: 18px;color: black;">John Marshal</span>
                                    <img src="../../../../assets/images/photo.png" style="width: 50% ;display: block;margin: auto;border: 2px solid #ddd;" />
                                    
                                    <div class="upload-btn-wrapper" style="width: 70%; margin-left: 28%; margin-top: 3%; cursor: pointer;">
                                        <button class="btn" style="font-size: 11px;">Update a Profile</button> <span *ngIf="uploadFileName">{{uploadFileName}}</span>
                                        <input type="file" #timeLineFile name="myfile" (change)="onFileChange($event)" accept="image/png, image/jpeg, image/jpg"/><br/>
                                    </div>

                                    <!--<button class='p-btn-sm' pButton type="button" style="margin-left: 190px;margin-bottom: 10px;" label="File">
                                        <input id="imgInp" contextmenu="Select" style="margin-top:8px" type="file" accept="image/png, image/jpeg, image/jpg "><br/>
                                    </button>-->

                                    <table>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">DATE OF BIRTH</span><br/>
                                                <input type="text" value="7 December 1972" id="txtFromDate" class="form-control-lead lead-text" name="from">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">GENDER</span><br/>
                                                <input type="text" value="Male" ng-model="Gender" class="form-control-lead lead-text" id="txtgender">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">MARTIAL STATUS</span><br/>
                                                <input type="text" value="Married" ng-model="MartialStatus" id="txtMartial" class="form-control-lead lead-text ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">PHONE</span><br/>
                                                <input type="text" value="415-555-1229" ng-model="Phone" id="txtphone" class="form-control-lead lead-text ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">ADDRESS</span><br/>
                                                <textarea rows="4" ng-model="Paddress" id="txta2" class="form-control-lead lead-textArea">
                                                    17 Daws Road Portland OR 97006
                                                </textarea>
                                                <!--<input type="text" value="17 Daws Road Portland OR 97006" ng-model="Address" id="txtaddress" style="height: 50px;" class="form-control-lead lead-text ">-->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">ETHNCITY</span><br/>
                                                <input type="text" value="Asian" ng-model="EthnCity" id="txtcity" class="form-control-lead lead-text ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">LANGUAGE SPOKEN</span><br/>
                                                <input type="text" value="English" ng-model="Language" id="txtlanguage" class="form-control-lead lead-text ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <hr style="border: 1px solid #dddddd;margin-left: 1px;margin-top: 10%;" />
                                                <span class="dimgrey">GUARDIAN</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">RELATIONSHIP</span><br/>
                                                <input type="text" value="Sister" ng-model="RelationShip" id="txtrlt" class="form-control-lead lead-text ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">NAME</span><br/>
                                                <input type="text" value="Martha Shan" ng-model="Gname" id="txtn1" class="form-control-lead lead-text ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">PHONE</span><br/>
                                                <input type="text" value="415-555-1229" ng-model="Gphone" id="txtp1" class="form-control-lead lead-text ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">ADDRESS</span><br/>
                                                <textarea rows="4" ng-model="Paddress" id="txta2" class="form-control-lead lead-textArea">
                                                    1357 Amber Road Portland OR 97006
                                                </textarea>
                                                <!--<input type="text" value="1357 Amber Road Portland OR 97006" ng-model="Gaddress" id="txtad1" style="HEIGHT: 50PX;" class="form-control-lead lead-text ">-->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <hr style="border: 1px solid #dddddd;margin-left: 1px;margin-top: 10%;" />
                                                <span class="dimgrey">PROVIDER</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">NAME</span><br/>
                                                <input type="text" value="Ashby Medical Center" ng-model="PRoviderName" id="txtn2" class="form-control-lead lead-text ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">PHONE</span><br/>
                                                <input type="text" value="415-555-1229" ng-model="Pphone" id="txtp2" class="form-control-lead lead-text ">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="lead-heading">ADDRESS</span><br/>
                                                <textarea rows="4" ng-model="Paddress" id="txta2" class="form-control-lead lead-textArea">
                                                    1002 Healthcare DR Portland OR 97006
                                                </textarea>
                                            </td>
                                        </tr>
                                    </table>
                            </td>
                            <td style="width: 55%;">
                                <div class="verticalLine">
                                <div>
                                    <table style="width: 100%;">
                                        <tr>
                                            <td>
                                                <span class="dimgrey">IMPORTANT NOTES</span><br />
                                                <i class="fa fa-plus-square" style="font-size:25px;color:black"></i>
                                                <span style="margin-left: 2%; font-weight: 700; font-size: 18px;color: black;">Allergies</span><br />
                                                <span class="lead-italic">You have <span style="font-weight: bold;">8</span> different allergies.</span><br/>
                                            </td>
                                            <td>
                                                <button class='p-btn-sm' pButton type="button" style="float: right;margin-bottom: 10px;" label="Add More" ></button>
                                            </td>
                                        </tr>
                                    </table>
                                    
                                    <!--<input class="btn btn-success lead-addrow" type="button" ng-click="Add(LeadAllergy.length)" value="InsertRow" />-->
                                    
                                    <div style="margin-left: 5%; height: 110px; overflow-y: auto;">
                                        <table id="tableadAllergyDetails" style="width: 100%; border-collapse:collapse;" class="table">
                                            <tbody>
                                                <tr style="border-bottom: 1px solid #ddd">
                                                    <th style="text-align: left; font-weight: bold;">NAME</th>
                                                    <th style="text-align: left; font-weight: bold;">SEVERITY</th>
                                                    <th style="text-align: left; font-weight: bold;">ACTION</th>
                                                </tr>
                                                <tr style="border-bottom: 1px solid #ddd">
                                                    <td>
                                                        <input type="text" id="txt1" value="Bee Stings" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                    <td>
                                                        <input type="text" id="txt2" value="Severe" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                    <td>
                                                        <input type="text" id="txt3" value="Anaphyiactic Shock" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                </tr>
                                                <tr style="border-bottom: 1px solid #ddd">
                                                    <td>
                                                        <input type="text" id="txt1" value="Bee Stings" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                    <td>
                                                        <input type="text" id="txt2" value="Severe" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                    <td>
                                                        <input type="text" id="txt3" value="Anaphyiactic Shock" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                </tr>
                                                <tr style="border-bottom: 1px solid #ddd">
                                                    <td>
                                                        <input type="text" id="txt1" value="Bee Stings" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                    <td>
                                                        <input type="text" id="txt2" value="Severe" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                    <td>
                                                        <input type="text" id="txt3" value="Anaphyiactic Shock" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                </tr>
                                                <tr style="border-bottom: 1px solid #ddd">
                                                    <td>
                                                        <input type="text" id="txt1" value="Bee Stings" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                    <td>
                                                        <input type="text" id="txt2" value="Severe" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                    <td>
                                                        <input type="text" id="txt3" value="Anaphyiactic Shock" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                </tr>
                                                <tr style="border-bottom: 1px solid #ddd">
                                                    <td>
                                                        <input type="text" id="txt1" value="Bee Stings" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                    <td>
                                                        <input type="text" id="txt2" value="Severe" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                    <td>
                                                        <input type="text" id="txt3" value="Anaphyiactic Shock" style="HEIGHT: 25PX;width:95%;" class="form-control-lead lead-text ">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> 
                                </div>

                                <hr style="border: 1px solid #dddddd;margin-left: 1px;MARGIN-TOP: 26PX;" /><br/>
                                <i class="fa fa-hospital" style="font-size:25px;color:black"></i>
                                <span style="margin-left: 2%; font-weight: 700; font-size: 18px;color: black;">Appointments</span><br />
                                <span class="lead-italic ">You have <span style="font-weight: bold;">2</span> upcoming immunizations.</span><br /><br />
                                <!--<p class="lead-border"></p>
                                <h1 class="lead-bordertext"> Due BY DEC 2020</h1><br /><br /><br /><br />-->
                                <div class="p-grid" style="margin-left: 5%;">
                                    <fieldset style="width: 45%;">
                                        <legend>DUE BY <span style="font-weight: bold;">DEC 2020</span></legend>
                                        <label for="fname">NAME:</label><br/>
                                        <input type="text" style="font-weight: bold; border: none;" value="Influenza Virus Vaccine" id="fname" name="fname"><br><br>
                                        <label for="lname">TYPE AND DOSE:</label><br/>
                                        <input type="text" style="font-weight: bold; border: none;" value="Injection" id="lname" name="lname"><br><br>
                                        <label for="email">VALUE / UNIT:</label><br/>
                                        <input type="email" style="font-weight: bold; border: none;" value="50 / mcg" id="email" name="email"><br><br>
                                        <label for="birthday">EDUCATION / INSTRUCTIONS:</label><br/>
                                        <input type="text" style="font-weight: bold; border: none;width: 100%;" value="Possible flu-like symptoms for 3 days" id="birthday" name="birthday"><br><br> 
                                    </fieldset>
                                    <fieldset style="width: 45%;">
                                        <legend>DUE BY <span style="font-weight: bold;">DEC 2020</span></legend>
                                        <label for="fname">NAME:</label><br/>
                                        <input type="text" style="font-weight: bold; border: none;" value="Influenza Virus Vaccine" id="fname" name="fname"><br><br>
                                        <label for="lname">TYPE AND DOSE:</label><br/>
                                        <input type="text" style="font-weight: bold; border: none;" value="Injection" id="lname" name="lname"><br><br>
                                        <label for="email">VALUE / UNIT:</label><br/>
                                        <input type="email" style="font-weight: bold; border: none;" value="50 / mcg" id="email" name="email"><br><br>
                                        <label for="birthday">EDUCATION / INSTRUCTIONS:</label><br/>
                                        <input type="text" style="font-weight: bold; border: none;width: 100%;" value="Possible flu-like symptoms for 3 days" id="birthday" name="birthday"><br><br> 
                                    </fieldset>
                                </div>
                                
                                <hr style="border: 1px solid #dddddd;width: 95%;margin-left: 1px;MARGIN-TOP: 26PX;" /><br/>
                                
                                <table style="width: 100%;">
                                    <tr>
                                        <td>
                                            <i class="fa fa-file" style="font-size:25px;color:black"></i>
                                            <span style="margin-left: 2%; font-weight: 700; font-size: 18px;color: black;">Plan of Care - PREMIUM MED CARE</span><br />
                                            <span class="lead-italic ">You have <span style="font-weight: bold;">3</span> recommendations from your doctor.</span>
                                        </td>
                                        <td>
                                            <button class='p-btn-sm' pButton type="button" style="float: right;margin-bottom: 10px;" label="Add More" ></button>
                                        </td>
                                    </tr>
                                </table>
                                
                                <!--<input class="btn btn-success lead-addrow" type="button" ng-click="Addplan(LeadPlan.length)" value="InsertRow" />-->

                                <div style="margin-left: 5%; height: 300px; overflow-y: auto;">
                                    <table id="tabplanDetails" style="width: 100%;border-collapse:collapse;" class="table">
                                        <tbody>
                                            <tr style="border-bottom: 1px solid #ddd">
                                                <th style="text-align: left; font-weight: bold;">NAME</th>
                                                <th style="text-align: left; font-weight: bold;">PLANNED DATE </th>
                                                <th style="text-align: left; font-weight: bold;">INSTRUCTIONS</th>
                                            </tr>
                                            <tr style="border-bottom: 1px solid #ddd">
                                                <td>
                                                    <input type="text" id="txt11" value="Office consultation" style="HEIGHT: 25PX;width:95%" class="form-control-lead ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched lead-text ">
                                                </td>
                                                <td>
                                                    <input type="text" id="txt12" value="1 June 2020" style="HEIGHT: 25PX;width:95%" class="form-control-lead ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched lead-text ">
                                                </td>
                                                <td>
                                                    <input type="text" id="txt13" value="Office consultation" style="HEIGHT: 25PX;width:95%" class="form-control-lead ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched lead-text ">
                                                </td>
                                            </tr>
                                            <tr style="border-bottom: 1px solid #ddd">
                                                <td>
                                                    <input type="text" id="txt11" value="Chest X-ray" style="HEIGHT: 25PX;width:95%" class="form-control-lead ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched lead-text ">
                                                </td>
                                                <td>
                                                    <input type="text" id="txt12" value="15 June 2020" style="HEIGHT: 25PX;width:95%" class="form-control-lead ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched lead-text ">
                                                </td>
                                                <td>
                                                    <input type="text" id="txt13" value="Chest X-ray" style="width: 100%;HEIGHT: 25PX;width:95%" class="form-control-lead ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched lead-text ">
                                                </td>
                                            </tr>
                                            <tr style="border-bottom: 1px solid #ddd">
                                                <td>
                                                    <input type="text" id="txt11" value="Office consultation" style="HEIGHT: 25PX;width:95%" class="form-control-lead ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched lead-text ">
                                                </td>
                                                <td>
                                                    <input type="text" id="txt12" value="1 June 2020" style="HEIGHT: 25PX;width:95%" class="form-control-lead ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched lead-text ">
                                                </td>
                                                <td>
                                                    <input type="text" id="txt13" value="" style="HEIGHT: 25PX;width:95%" class="form-control-lead ng-pristine ng-empty ng-invalid ng-invalid-required ng-touched lead-text ">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br>
                                
                                <!--<button type="button" class="btn btn-primary" ng-disabled="IsDisabled" ng-click="btnUpdateLead(this)">Update</button><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />-->
                                <!--<button class='p-btn-sm' pButton type="button" style="float: right;margin-bottom: 10px;" label="Update" ></button><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />-->
                                <span style="margin-left: 70%; font-weight: 700; font-size: 18px;color: #585353;">Plan valid till: DEC 2020</span><br />
                            </div>
                            </td>
                        </tr>
                    </table>
                </div>
        </div>
</div>

</div>
