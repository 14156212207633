<div class="p-grid user-container">
    <div class="p-col-12">
        <panel [panelConfig]="viewPanel1">
            <div panelHeader>
                <span>Profile Information</span>
            </div>
            <div panelBody style="overflow-y: auto; overflow-x: hidden;  ">
                <div class="ui-fluid" style="padding: 10px">
                    <div class="p-grid">
                        <div class="p-col-5">
                            <div class="img-form">
                                <div class="user-profile-img">
                                    <img [src]="image_url" alt="User Profile" (error)="handleImageError($event)">
                                    <div class="overlay">
                                        <a (click)="profileImage.click()"><i class="fas fa-camera"></i></a>
                                        <input type="file" #profileImage name="myfile" (change)="onFileChange($event)"
                                            accept="image/png, image/jpeg, image/jpg" style="display: none;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-7">
                            <form [formGroup]="changePasswordForm" (ngSubmit)="savePassword()">
                                <div class="ui-fluid">
                                    <div class="p-field ">
                                        <label for="newPwd" style="margin-bottom: 0.2rem;" class="userLabel">New Password</label>
                                        <!-- <input autocomplete="off"  type="password" style="width: 90%;" formControlName="newPassword" pInputText>  -->
                                        <div class="ui-inputgroup" style="width: 90%;">
                                            <input autocomplete="off" formControlName="newPassword" 
                                                 placeholder="New password" id="newPwd"
                                                [type]="showNewPassword ? 'text' : 'password'" pInputText>

                                            <span class="ui-inputgroup-addon password-group"
                                                (click)="showNewPassword = !showNewPassword">
                                                <i *ngIf="showNewPassword" class="pi pi-eye"></i>
                                                <i *ngIf="!showNewPassword" class="pi pi-eye-slash"></i>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="p-field">
                                        <label for="cnfrmPwd" style="margin-bottom: 0.2rem;" class="userLabel">Confirm New Password</label>
                                        <input formControlName="confirmPassword" placeholder="Confirm password" autocomplete="off" style="width: 90%;"
                                            type="password" (input)="checkPassword()" pInputText>
                                        <p class="error-msg" *ngIf="!isMatch">Password mismatch</p>
                                     
                                    </div>
                                </div>
                                <button pButton type="submit" label="Change Password" class="ui-button-raised"
                                    style="width: 90%;" [disabled]="!changePasswordForm.valid"></button>
                            </form>
                        </div>
                    </div>
                    <div style="display: flex;flex-wrap:wrap">  
                    <div class="p-col-6">
                        <label for="firstname1"  class="userLabel">Full Name</label>
                        <input id="firstname1" type="text" pInputText placeholder="Fullname" readonly
                            value="{{formData['fullname']}}">
                    </div>

                    <div class="p-col-6">
                        <label for="email"  class="userLabel">Email</label>
                        <input *ngIf="formData['auth'] && formData['auth']['email']" id="email" type="email" pInputText
                            placeholder="Email" readonly value="{{formData['auth']['email'] || ''}}">
                    </div>
                    <div class="p-col-6">
                        <label for="mobile" style="margin-bottom: 0.2rem;" class="userLabel">Mobile</label>
                        <input readonly [value]="formData['mobile'] || ''" id="mobile" type="text" pInputText
                            placeholder="Mobile">
                    </div>
                    <div class="p-col-6">
                        <label for="username" style="margin-bottom: 0.2rem;" class="userLabel">Username</label>
                        <input readonly [value]="formData['auth']['username'] || ''" id="username" type="text"
                            pInputText placeholder="Username">
                    </div>
                    <div class="p-col-6">
                        <label for="designation" style="margin-bottom: 0.2rem;" class="userLabel">Designation</label>
                        <input readonly [value]="formData['designationName'] || ''"
                            id="designation" type="text" pInputText placeholder="designation">
                    </div>
                    <div class="p-col-6">
                        <label for="manager" style="margin-bottom: 0.2rem;" class="userLabel">Manager</label>
                        <input readonly [value]="formData['managerName'] || ''" id="manager" type="text"
                            pInputText placeholder="manager">
                    </div>

                    <div class="p-col-6">
                        <label for="user_role" style="margin-bottom: 0.2rem;" class="userLabel">User Role</label>
                        <input readonly [value]="formData['role']['role_name'] || ''" id="user_role" type="text"
                            pInputText placeholder="user role">
                    </div>
                   
                    <div class="p-col-6">
                        <label for="department" style="margin-bottom: 0.2rem;" class="userLabel">Department</label>
                        <input readonly [value]="(formData['department'])?formData['department']['department_name'] : ''"
                            id="department" type="text" pInputText placeholder="department">
                    </div>
                    <div class="p-col-6">
                        <label for="supervisorName" style="margin-bottom: 0.2rem;" class="userLabel">Supervisor</label>
                        <input readonly [value]="formData['supervisorName'] || ''" id="supervisorName"
                            type="text" pInputText placeholder="supervisor">
                    </div>

                    <div class="p-col-6">
                        <label for="supportLevel" style="margin-bottom: 0.2rem;" class="userLabel">SupportLevel</label>
                        <input readonly [value]="formData['support_level']['level'] || ''" id="supportLevel"
                            type="text" pInputText placeholder="supportLevel">
                    </div>
                </div>

                </div>
            </div>
        </panel>
    </div>
    <!-- <div class="p-col-12">
        <panel [panelConfig]="viewPanel1">
            <div panelHeader>
                <span style="visibility: hidden;">Basic Information</span>
            </div>
            <div panelBody style="height: 50vh; overflow-y: auto; overflow-x: hidden;">
                <div class="p-grid">

                    <div class="p-col" style="padding: 15px">
                        <div class="ui-fluid">
                            <div class="p-field">
                                <label for="designation" style="margin-bottom: 0.2rem;" class="userLabel">Designation</label>
                                <input readonly [value]="formData['designationName'] || ''"
                                    id="designation" type="text" pInputText placeholder="designation">
                            </div>
                            <div class="p-field">
                                <label for="manager" style="margin-bottom: 0.2rem;" class="userLabel">Manager</label>
                                <input readonly [value]="formData['managerName'] || ''" id="manager" type="text"
                                    pInputText placeholder="manager">
                            </div>

                            <div class="p-field">
                                <label for="user_role" style="margin-bottom: 0.2rem;" class="userLabel">User Role</label>
                                <input readonly [value]="formData['role']['role_name'] || ''" id="user_role" type="text"
                                    pInputText placeholder="user role">
                            </div>
                            <div class="p-grid">


                            </div>
                            <div class="p-field">
                                <label for="department" style="margin-bottom: 0.2rem;" class="userLabel">Department</label>
                                <input readonly [value]="(formData['department'])?formData['department']['department_name'] : ''"
                                    id="department" type="text" pInputText placeholder="department">
                            </div>
                            <div class="p-field">
                                <label for="supervisorName" style="margin-bottom: 0.2rem;" class="userLabel">Supervisor</label>
                                <input readonly [value]="formData['supervisorName'] || ''" id="supervisorName"
                                    type="text" pInputText placeholder="supervisor">
                            </div>

                            <div class="p-field">
                                <label for="supportLevel" style="margin-bottom: 0.2rem;" class="userLabel">SupportLevel</label>
                                <input readonly [value]="formData['support_level']['level'] || ''" id="supportLevel"
                                    type="text" pInputText placeholder="supportLevel">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </panel>
    </div> -->
</div>
<message-modal #messageModal></message-modal>