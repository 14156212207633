import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from './modules/shared/confirmation-dialog/confirmation-dialog.component';
import { socketService } from './services/socket.service';
import { Subscription } from 'rxjs';
import { ApiService } from './services/api.service';
import { Socket } from 'ngx-socket-io';
import { ModalComponent } from './modules/shared/modal/modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @ViewChild('confirmDialog') confirmDialog: ConfirmationDialogComponent;
  @ViewChild('askForYourLogout') askForYourLogout: ConfirmationDialogComponent;
  @ViewChild('messageModal') messageModal: ModalComponent;

  @Output() TicketSearchEvent = new EventEmitter();

  title = 'KGIS';

  menuBarToggle: boolean = true;
  isLogged: boolean = true;
  SocketEventService$: Subscription;
  logoutRequestStatus$: Subscription;
  forceLogout = false;
  standalone;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private socketService: socketService,
    private apiService: ApiService,
    private socket: Socket
  ) {
    this.standalone = true;
  }
  ngOnInit(): void {
    this.appTitle();
    this.route.data.subscribe((data) => {});
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((rout) => {
          while (rout.firstChild) {
            rout = rout.firstChild;
          }
          return rout;
        }),
        filter((rout) => rout.outlet === 'primary'),
        mergeMap((rout) => rout.data)
      )
      .subscribe((data) => {
        if (data.standalone) {
          this.standalone = data.standalone;
        } else {
          this.standalone = false;
        }
      });

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     console.log('app', event.url)
    //     let url = event.url.split('?');
    //     // if (url[0].includes('/auth')) {
    //     //   this.isLogged = false;
    //     // } else {
    //     //   this.isLogged = true;
    //     // }
    //     this.isLogged = (url[0].includes('/auth')) ? false : true;
    //   }
    // })

    // this.logoutRequestStatus$ = this.socketService.getMessage('response-for-logout').subscribe((msg)=>{
    //   console.log(msg)
    //   this.messageModal.closeModal()
    //   if(msg.status=='rejected'){
    //     this.messageModal.warnDialog("User refused to loggout")
    //   }else{
    //     this.messageModal.successDialog("User accepted you request.Kindly try to login now")
    //   }
    // })
    this.SocketEventService$ = this.socketService
      .getMessage('ask-for-logout')
      .subscribe((msg) => {
        this.forceLogout = true;
        this.confirmLogout();
      });
  }
  _menuBarToggle(e) {}

  logout(e?) {
    this.confirmDialog.open({
      message: 'Are you sure, you want to logout?',
      rejectLabel: 'Cancel',
      confirmLabel: 'Ok',
    });
  }
  async confirmLogout(e?) {
    if (this.forceLogout == true) {
      setTimeout(() => {
        localStorage.clear();
        this.router.navigate(['/auth']).then(() => {
          this.messageModal.warnDialog(
            'Another user logged in with same credentials. Kindly login again.'
          );
        });
        this.socketService.disconnect();
      }, 1000);
    } else {
      localStorage.clear();
      this.router.navigate(['/auth']).then(() => {
        window.location.reload();
      });
      this.socketService.disconnect();
    }
    this.forceLogout = false;
  }

  async rejectLogout() {
    if (this.forceLogout == true) {
      let response = await this.apiService
        .get(`logout-request?status=rejected`)
        .toPromise();
    }
  }

  appTitle() {
    const title = document.getElementById('homeTitle') as HTMLTitleElement;
    const favIcon = document.getElementById('favIcon-home') as HTMLLinkElement;
    if (environment.client == 'kgis') {
      title.innerHTML = 'KGIS';
      favIcon.setAttribute('href', 'favicon.ico');
    } else {
      title.innerHTML =
        'Aerodyne Group - Drone Tech, Data Tech & Digital Transformation';
      favIcon.setAttribute('href', '../assets/images/aerodyne-favicon.png');
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.logoutRequestStatus$) this.logoutRequestStatus$.unsubscribe();
    if (this.SocketEventService$) this.SocketEventService$.unsubscribe();
  }
}
