<div>
    <div class="position-center ">
        <p-card>
            <div class="timeline-card-header">
                <!-- <h2>HELPDESK <span class="highlight-1">CMS</span></h2> -->
                <img src="../../../../assets/images/aerodyne-green-logo.png" class="logo-chart-screen" alt="Aerodyne">
            </div>
            <div class="timeline-card-body">
                <h4 class="remove-mg-b bold">
                    Ticket#: {{updateTimeForm.get('ticketId').value || ''}}</h4>
                <p>Name : {{user_name}}</p>
                <form [formGroup]="updateTimeForm" *ngIf="ticketcurrentStatus">
                    <label for='comments'>Leave a comment</label>
                    <div class="p-field">
                        <textarea style="width: 100%;" id='comments' pInputTextarea [rows]="4" [maxlength]="maxChars" #comments formControlName="comments"></textarea>
                        <span style="float: right; font-size: smaller;" class="pull-right char"><em>{{ 300 - updateTimeForm?.value?.comments?.length }} characters
                                left</em></span>
                        <br>
                        <input name="file" #resolveFile type="file" (change)="onFileChange($event)" />
                    </div>
                </form>
            </div>
            <div class="timeline-card-footer" *ngIf="ticketcurrentStatus">
                <button class='custom-btn' pButton type="button" label="Save My
                Comments" (click)="submitComment()"></button>
            </div>
        </p-card>

        <p-card class="timeline-card">
            <ul class="custom-list">
                <li class="item" *ngFor="let record of recordList">
                    <span class="list-item-bullet">
                        <p class="sub-label-item">
                            <span>{{record['createdAt'] | date: 'EEE, dd MMM yyyy h:mm a'}}</span> &nbsp;
                    <span class="highlight-1" [ngStyle]="{'color': (record['commentBy'] && record['ticket_status']) ? record['ticket_status']['status']['colorCode'] : ( record['userType'] && (record['userType'] | lowercase) == 'customer') ? '#6D214F' : '#2C3A47' }">{{(record['userType'] && (record['userType'] | lowercase)  == 'customer' )? 'You - ' :  record['commentBy']['fullname'] +  ' - '}}
                            </span> commented
                    <i *ngIf="record['attachment']" style="margin-left: 5px;" class="pi pi-paperclip pointer-cursor" (click)="openAttachment(record['attachment']['url'])">
                            </i>
                    <span *ngIf="record['attachment']" style="margin-left: 2px;cursor: pointer;" (click)="openAttachment(record['attachment']['url'])">{{record['attachment']['filename']| slice:19:50}}{{record['attachment']['filename'].length>50? '...':''}}</span>
                    </p>
                    </span>
                    <!-- <br> -->
                    <!-- <p>Ticket Description:</p> -->
                    <div class="item-description innerHtmlStyle" [ngStyle]="{'borderLeftColor': (record['commentBy'] && record['ticket_status']) ? record['ticket_status']['status']['colorCode'] : ( record['userType'] && (record['userType'] | lowercase) == 'customer') ? '#6D214F' : '#2C3A47' }"
                        [innerHtml]="record['comment']"></div>
                </li>
            </ul>
        </p-card>
    </div>
</div>
<message-modal #messageModal></message-modal>