<p-card>
    <div class="p-grid p-align-center   vertical-container ">
        <div class="p-col-4 p-xl-2">
            <h3 class="labls">From</h3>
            <p-calendar [showIcon]="true" [(ngModel)]="from" [maxDate]="today" [inputStyle]="{'width':'75%'}"></p-calendar>
        </div>
        <div class="p-col-4  p-xl-2">
            <h3 class="labls">To</h3>
            <p-calendar [showIcon]="true" [minDate]="from" [maxDate]="today" [(ngModel)]="to" [inputStyle]="{'width':'75%'}">
            </p-calendar>
        </div>
        <!-- <div class="p-col-1"></div> -->
        <div class="p-col-4  p-xl-2" *ngIf="options['showSelectFilter']">
            <h3 class="reprt">Report</h3>
            <p-dropdown [options]="filterOptions" optionLabel="name" appendTo="body" showClear="true" placeholder="Select filter"  (onChange)="consoleChange()" [(ngModel)]="filterApplied"></p-dropdown>
        </div>
        <div class="p-col" style="align-self: flex-end;margin-left: 0.5%;">
            <span style="display: flex;align-items: center;" >
               <span>Last&nbsp;&nbsp;</span> <p-inputNumber prefix="" suffix="" styleClass="input-size" [(ngModel)]="dayslide" mode="decimal" [min]="1"
                    [max]="100" placeholder="0" size="1">
                </p-inputNumber><span>&nbsp;&nbsp;{{ dayslide > 1 ? 'Days' : 'Day' }}</span>
                <p-slider [min]="1" [max]="100" (onChange)="sliderChange($event.value)" class="m-l"
                    [(ngModel)]="dayslide" [style]="{'width':'14em'}"></p-slider>
            </span>
        </div>
       
        <div class="p-col p-col-align-end" style="align-self: flex-end;text-align:end" >
            <button pButton type="button" icon="pi pi-plus" [label]="options['fetchBtnLabel'] || 'Fetch Summary'" (click)="fetchData()" [disabled]="!from || !to || (!filterApplied && options['showSelectFilter'])"
                class="fetchbtn"  ></button>
        </div>
    </div>
</p-card>
<div class="p-grid p-mt-3" style="overflow: auto; max-height: 80%;">
    <div class="p-col-12"  >
        <p-panel *ngIf="dataTable" styleClasss="reportpanel">
            <p-header>
                {{dataTable.heading}}
                <span *ngIf="dataTable.fromDate">
                    &nbsp;&nbsp;
                    Filter:
                    <span class="badgereport">{{dataTable.fromDate}} - {{dataTable.toDate}} </span>
                    &nbsp;&nbsp;
                    <span class="badgereport">{{dataTable.filter}}</span>
                </span>
                
            </p-header>
            <data-table  [dataTable]="dataTable">
            </data-table>
        </p-panel>
    </div>
</div>