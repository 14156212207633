import { Component, OnInit,Input ,SimpleChange,ViewChild,EventEmitter,Output} from '@angular/core';
import { IDataTable } from '../../../models/modules/shared/data-table.model';
import { DataTableComponent } from '../data-table/data-table.component';
import * as moment from 'moment';
@Component({
  selector: 'report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  @Output()
  fetchDataEvent : EventEmitter<Object>= new EventEmitter<Object>();
  @ViewChild(DataTableComponent) dataTableFunc : DataTableComponent

  @Input() dataTable: IDataTable;
  @Input() options;

  dayslide;
  today = new Date()

  filterOptions = [
    { name:"Ticket", value: "ticket" },
    { name:"Payment", value: "payment" },
    { name:"Agent Report", value: "agent" },
    { name:"Ticket Aging", value: "ticket-aging" },
    { name:"Call Report", value: "call-report" },
    { name:"Email Report", value: "email-report" }
  ]
  filterApplied = null;
  constructor() { }

  from: Date = new Date()
  to : Date
  ngOnInit(): void {
    console.log("Report")
  }
  consoleChange() {
    console.log(this.filterApplied)
  }

  fetchData(){
    let data = {
      from : this.from.getTime() ?? '',
      to : this.to.getTime() ?? '',
      filter : (this.filterApplied)?this.filterApplied.value : ''
    }
    this.dataTable = null;
    this.fetchDataEvent.emit(data)
  }

  ngAfterViewInit(){

  }

  ngOnChanges(changes : SimpleChange){
    console.log(changes['dataTable'].currentValue)
    this.dataTable = changes['dataTable'].currentValue;
    if (changes['options']) this.options = changes['options'].currentValue;
    // this.dataTable.scrollHeight = 200

    // this.dataTableFunc.getTableDate(changes['dataTable'].currentValue)
  }

  sliderChange(value): void {
    console.log(this.dayslide);
    const Today = moment().format('YYYY-MM-DD');
    const LastDay = moment().add(-1 * value, 'days').format('YYYY-MM-DD');
    this.from = new Date(LastDay);
    this.to =  new Date(Today);
  }
}
