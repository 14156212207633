<div class="payment-page">
    <div>

        <div class=" text-center price-header basic-bg-3"><b>KGIS Ticket
                #{{pricedData.ticket_id}} - Invoice screen</b> </div>
        <div *ngIf="type" class="card-body">
            <form id="pricingForm" [formGroup]="priceFormGroup">
                <div class="container-fluid">
                    <div class="p-grid">
                        <div *ngIf="type == refType.USER" class="p-sm-6">
                            <div class="ui-fluid p-formgrid p-grid">
                                <div class="p-field p-sm-12 p-md-6">
                                    <label>Ticket ID</label>
                                    <input readonly type="text" id="tokenId" formControlName="ticket_id" pInputText />
                                </div>

                                <div class="p-field p-sm-12 p p-md-6">
                                    <label>Price *</label>
                                    <input formControlName="price" type="number" min="0" id="price" pInputText (input)="updateBilledAmount()" />
                                </div>
                                <!-- </div>
                            <div class="ui-fluid p-formgrid p-grid"> -->
                                <div class="p-field p-sm-12 p p-md-6">
                                    <label>Discount</label>
                                    <input formControlName="discount" type="number" id="discount" value="0.00" min="0" step=".01" (input)="updateBilledAmount()" pInputText />
                                </div>
                                <div class="p-field p-sm-12 p p-md-6">
                                    <label>Billed Amount in INR</label>
                                    <input readonly formControlName="billed_amount" type="number" id="billedAmount" pInputText />
                                </div>
                                <!-- </div>
                            <div class="ui-fluid p-formgrid p-grid"> -->
                                <div class="p-field p-md-12">
                                    <label>Description *</label>
                                    <textarea type="text" id="description" formControlName="description" pInputTextarea cols="30"></textarea>
                                </div>
                                <div class="p-field p-col-7">
                                    <label>Tax description *</label>
                                    <textarea type="text" id="description" placeholder="e.g. Tax at 18% GST" formControlName="tax_description" pInputTextarea cols="20" rows="1"></textarea>
                                </div>
                                <div class="p-field p-col-5">
                                    <label>Tax Amount *</label>
                                    <input formControlName="tax_amount" type="number" min="0" pInputText (input)="updateBilledAmount()" />
                                </div>
                                <div class="p-field p-col-12">
                                    <label>Notes</label>
                                    <textarea type="text" id="description" formControlName="notes" pInputTextarea cols="30" rows="1" [maxlength]="max_note_char"></textarea>
                                </div>
                            </div>

                        </div>
                        <div class="p-sm-6" [ngClass]="{'col-sm-12': type == refType.CUSTOMER}">
                            <div class="card invoice-border">
                                <div class="card-body">
                                    <div class="text-center">
                                        <img class="mb-10" style="width: 50%; height: auto;" src="../../../../assets/images/helpdesklogo.png" alt="LOGO"><br>
                                        <div class="mb-10">
                                            <strong>
                                                <u>
                                                    BILL
                                                </u>
                                            </strong>
                                        </div>
                                    </div>
                                    <div style="padding-bottom: 35px;">
                                        <div style="float: left;">
                                            <span>
                                                <label for="">DATE:</label>
                                                <strong
                                                    *ngIf="pricedData && pricedData.created_at">{{pricedData.created_at | date}}</strong>
                                            </span>
                                        </div>
                                        <div style="float: right;">
                                            <span>
                                                <label for="">INV#:</label>
                                                <strong>{{pricedData.invoice_no}}</strong>
                                            </span>
                                        </div>
                                    </div>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <strong>
                                                        <u>
                                                            Particulars
                                                        </u>
                                                    </strong>
                                                </th>
                                                <th class="text-end" scope="col">
                                                    <strong>
                                                        <u>
                                                            Price [INR]
                                                        </u>
                                                    </strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{getControllerValue('description')}}

                                                </td>
                                                <td class="text-end">{{getControllerValue('price') | number:'1.2-3'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td class="text-end">{{getControllerValue('price') | number:'1.2-3'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Discounts Applied</td>
                                                <td class="text-end">
                                                    {{getControllerValue('discount') | number:'1.2-3'}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="(pricedData && pricedData.tax_amount) || (getControllerValue('tax_amount'))">
                                                <td>{{getControllerValue('tax_description')}}</td>
                                                <td class="text-end">
                                                    {{getControllerValue('tax_amount') | number:'1.2-3'}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>
                                                        Billed Amount [INR]
                                                    </strong>
                                                </td>
                                                <td class="text-end">
                                                    {{getControllerValue('billed_amount') | number:'1.2-3'}}
                                                </td>
                                            </tr>
                                            <tr *ngIf="pricedData && pricedData.paid_amount">
                                                <td><strong>
                                                        Paid Amount [INR]
                                                    </strong>
                                                </td>
                                                <td class="text-end">{{pricedData.paid_amount | number:'1.2-3'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <br>
            </form>

            <div class="p-grid">
                <div *ngIf="showSubmitButton" class="p-field p-col-12 text-end">

                    <button pButton (click)="createPayment()" type="button" class="p-btn-sm" label="Save & Generate Link"></button>
                </div>
                <div *ngIf="!showSubmitButton && type == refType.CUSTOMER" class="p-col-12 text-end">
                    <button pButton (click)="makePayment()" type="button" class="p-btn-sm" label="Click to Make Payment"></button>
                </div>
                <div *ngIf="!showSubmitButton && type == refType.USER" class="p-col-2"></div>
                <div *ngIf="!showSubmitButton && type == refType.USER" class="p-col-8 ui-fluid">

                    <label>Payment url</label>
                    <div class="p-field ui-inputgroup url-links">

                        <input type="text" pInputText placeholder="Payment url" [value]="pricedData.short_url" #paymenturl>
                        <button pButton class="p-btn-sm" type="button" id="button-addon1" (click)="copyInputMessage(paymenturl)" title="copy to clipboard" label="COPY"></button>
                    </div>
                    <br>
                    <label>Invoice url</label>
                    <div class="p-field ui-inputgroup url-links  mb-3">
                        <input type="text" pInputText placeholder="Invoice url" [value]="pricedData.invoice_url" #invoiceurl>
                        <button pButton class="p-btn-sm" type="button" id="button-addon2" (click)="copyInputMessage(invoiceurl)" title="copy to clipboard" label="COPY"></button>

                    </div>

                </div>
                <div *ngIf="!showSubmitButton && type == refType.USER" class="p-col-2"></div>
                <div *ngIf="!showSubmitButton && type == refType.USER" class="p-col-12 text-center">
                    <button pButton class="p-btn-sm" (click)="createNewInvoice()" type="button" class="btn-primary btn btn-sm mr-2" label="Create New Invoice"></button>
                </div>
            </div>
        </div>
    </div>
</div>