<div  class="main-page">
    <div *ngIf="!standalone" class="top">
        <header class="header-layout" (menuBarToggle)="menuBarToggle = $event" (logout)="logout($event)"  ></header>
    </div>
    <div class="middle">
        <sidebar-navigation *ngIf="!standalone" class="sidebar-layout" [ngClass]="{'hide': !menuBarToggle}" (logout)="logout($event)"  ></sidebar-navigation>
        <div class="app-body-layout" [ngClass]="{'extend': !menuBarToggle, 'standalone': standalone}">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="bottom" *ngIf="!standalone">
        <footer class="footer-layout"></footer>
    </div>
</div>

<!-- <div *ngIf="standalone"class="main-page">
    <router-outlet></router-outlet>
</div> -->
<progress-spinner ></progress-spinner>
<confirmation-dialog #confirmDialog  (confirm)="confirmLogout($event)"></confirmation-dialog>
<confirmation-dialog #askForYourLogout (closeButton)="rejectLogout()" (confirm)="confirmLogout($event)"></confirmation-dialog>
<message-modal #messageModal ></message-modal>