<div class="payment-page">
    <div *ngIf="pricedData">

        <div class=" text-center price-header basic-bg-3"><b>KGIS Ticket
                <span *ngIf="pricedData.ticket"> #{{pricedData.ticket['ticketId'] || ''}}</span> - Invoice screen</b>
        </div>
        <div class="card-body">

            <div class="container-fluid">
                <div class="p-grid">
                    <div class="p-col-12 custom-font">
                        <div class="card invoice-border">
                            <div class="card-body">
                                <div class="text-center">
                                    <img class="mb-10" width="80px" height="30px" src="../../../../assets/images/eda.png" alt="LOGO"><br>
                                    <div class="mb-10">
                                        <strong>
                                            <u>
                                                BILL
                                            </u>
                                        </strong>
                                    </div>
                                </div>
                                <div style="padding-bottom: 35px;">
                                    <div style="float: left;">
                                        <span>
                                            <label for="">DATE:</label>
                                            <strong *ngIf="pricedData && pricedData.createdAt"> &nbsp;
                                                {{pricedData.createdAt | date}}</strong>
                                        </span>
                                    </div>
                                    <div style="float: right;">
                                        <span>
                                            <label for="">INV#:</label>
                                            <strong> &nbsp; {{pricedData.rzrInvoiceId}}</strong>
                                            <!-- <strong>inv_F6tS35mUMuJPJ5</strong> -->
                                        </span>
                                    </div>
                                </div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-start" scope="col">
                                                <strong>
                                                    <u>
                                                        Particulars
                                                    </u>
                                                </strong>
                                            </th>
                                            <th class="text-end" scope="col">
                                                <strong>
                                                    <u>
                                                        Price [INR]
                                                    </u>
                                                </strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{pricedData.description}}
                                            </td>
                                            <td class="text-end">{{pricedData['price'] | number:'1.2-3'}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td class="text-end">{{pricedData['price'] | number:'1.2-3'}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Discounts Applied</td>
                                            <td class="text-end">
                                                {{pricedData['discount'] | number:'1.2-3'}}
                                            </td>
                                        </tr>
                                        <tr *ngIf="(pricedData && pricedData.tax_amount) || (pricedData['tax_amount'])">
                                            <td>{{pricedData['tax_description']}}</td>
                                            <td class="text-end">
                                                {{pricedData['tax_amount'] | number:'1.2-3'}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>
                                                    Billed Amount [INR]
                                                </strong>
                                            </td>
                                            <td class="text-end">
                                                {{pricedData['billed_amount'] | number:'1.2-3'}}
                                            </td>
                                        </tr>
                                        <tr *ngIf="pricedData && pricedData.paid_amount">
                                            <td><strong>
                                                    Paid Amount [INR]
                                                </strong>
                                            </td>
                                            <td class="text-end">{{pricedData.paid_amount | number:'1.2-3'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <br>

            <div class="p-grid">
                <div class="p-col-12 text-end">
                    <button (click)="makePayment()" pButton type="button" class="p-btn-sm" label="Click to Make Payment"></button>
                </div>
            </div>
        </div>
    </div>
</div>