import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DialogModule} from 'primeng/dialog';
import {TooltipModule} from 'primeng/tooltip';
import {PanelModule} from 'primeng/panel';
import {SplitButtonModule} from 'primeng/splitbutton';
import { CardModule } from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {TabMenuModule} from 'primeng/tabmenu';
import {MenubarModule} from 'primeng/menubar';
import {EditorModule} from 'primeng/editor';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {FileUploadModule} from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ListboxModule} from 'primeng/listbox';
import {SliderModule} from 'primeng/slider';
import {InputNumberModule} from 'primeng/inputnumber';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TabViewModule} from 'primeng/tabview';
import {ColorPickerModule} from 'primeng/colorpicker';
import {MultiSelectModule} from 'primeng/multiselect';
import {PaginatorModule} from 'primeng/paginator';
import {SelectButtonModule} from 'primeng/selectbutton';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    MultiSelectModule,
    ButtonModule,
    TableModule,
    InputTextModule,
    ProgressSpinnerModule,
    DialogModule,
    TooltipModule,
    PanelModule,
    SplitButtonModule,
    CardModule,
    PanelModule,
    DropdownModule,
    TabMenuModule,
    MenubarModule,
    EditorModule,
    CheckboxModule,
    InputTextareaModule,
    FileUploadModule,
    CalendarModule,
    OverlayPanelModule,
    ListboxModule,
    SliderModule,
    InputNumberModule,
    MessageModule,
    ToastModule,
    RadioButtonModule,
    TabViewModule,
    ColorPickerModule,
    PaginatorModule,
    SelectButtonModule
  ]
})
export class PrimengModule { }
