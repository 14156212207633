import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { IPanel } from '../../../models/modules/shared/panel.model';
import { ApiService } from '../../../services/api.service';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'user-profile-view',
  templateUrl: './user-profile-view.component.html',
  styleUrls: ['./user-profile-view.component.css']
})
export class UserProfileViewComponent implements OnInit, OnChanges {
  @ViewChild("messageModal") messageModal: ModalComponent;
  @Input() formData: any;
  @Output() profileImgChanged = new EventEmitter();
  image_url;
  viewPanel1: IPanel = {
    showHeader: true
  }
  changePasswordForm: FormGroup
  isMatch: boolean = true
  placeholderImageUrl: string = '../../../../assets/images/photo.png';

  showNewPassword: boolean = false;
  
  updateProfileImage$: Subscription;

  constructor(private apiService: ApiService, private fb: FormBuilder, private userService: UserService) {
    this.changePasswordForm = this.fb.group({
      newPassword: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl(null, [Validators.required, Validators.minLength(6)])
    });
  }

  ngOnInit(): void {
  }

  checkPassword() {
    if (this.changePasswordForm.value['newPassword'] == '') {
      this.isMatch = true
      return false
    }
    if (this.changePasswordForm.value['newPassword'] == this.changePasswordForm.value['confirmPassword']) {
      this.isMatch = true
    } else {
      this.isMatch = false
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    this.changePasswordForm.reset()
    if (this.formData.profile_image) {
      console.log(this.formData.profile_image.url);
      console.log(this.formData.profile_image.url.substring(1));
      this.image_url = environment.api_url + this.formData.profile_image.url.substring(1);
      // this.formData.value.profile_image = this.formData.value.profile_image.id;
    }
    else
    {
      this.image_url = "";
    }
  }

  savePassword() {
    let value = {
      new_password: this.changePasswordForm.value['newPassword']
    }
    this.apiService.post('change-password', value).subscribe((e) => {
      if (e.status == true) {
        this.messageModal.successDialog(e.message)
        this.changePasswordForm.reset()
      }
    })
  }

  onFileChange(fileInput) {
    console.log("fileInput=",fileInput)
    const file = fileInput.target.files[0];
    if (file) {

      const formData = new FormData();
      formData.append('file', file);

      this.apiService.post('upload-file', formData).subscribe(res => {
        console.log(res)
        if (!res.status) return this.messageModal.errorDialog('Some error in uploading profile image');
        this.updateProfileImage(res['files']['url'], res['files']['id'])
        // this.uploadedId = res['files']['id'];
        console.log("lllll",environment.api_url + res.files.url);
        let clean_url = environment.api_url + res.files.url
        clean_url = clean_url.replace(/([^:]\/)\/+/g, "$1")
        console.log("lllll",clean_url);
        this.image_url = clean_url;
      });
    }

  }


  updateProfileImage(url, id) {


    const _body = { profilePic: id }
    this.updateProfileImage$ = this.apiService.post('change-profile', _body).subscribe(response => {

      console.log(response)
      if (!response.status) return  this.messageModal.errorDialog('Some error in saving profile image');
      let _session = this.userService.getSession();
      _session['profile_image'].url = url;
      this.userService.updateSession(_session);
      this.profileImgChanged.emit(true);

    })
  }

  handleImageError(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.placeholderImageUrl;
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.updateProfileImage$) this.updateProfileImage$.unsubscribe();
  }
}
