import { Component, OnInit, Input } from '@angular/core';
import { IVerticalTimeline } from '../../../models/modules/shared/vertical-timeline.model';
import  {environment} from '../../../../environments/environment'
@Component({
  selector: 'vertical-timeline',
  templateUrl: './vertical-timeline.component.html',
  styleUrls: ['./vertical-timeline.component.css']
})
export class VerticalTimelineComponent implements OnInit {

  @Input() verticalTimeline: IVerticalTimeline[];
  
  linkBaseUrl = environment.api_img_url;
  
  constructor() { }

  ngOnInit(): void {
  }

}
