import { Component, OnInit, Input, SimpleChange,NgModule, ViewEncapsulation, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { IPanel } from '../../../../models/modules/shared/panel.model';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ModalComponent } from '../../../shared/modal/modal.component';
import { ApiService } from "../../../../services/api.service";
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'intake-form',
  templateUrl: './intake-form.component.html',
  styleUrls: ['./intake-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IntakeFormComponent implements OnInit {
  @ViewChild("messageModal") messageModal: ModalComponent;
  @Input() formData: any;
  createIntakeForm: FormGroup;
  agentToken: any;
  assetId: any;
  intakeId: any;
  ticketDetails: any;
  @Output() onResult: EventEmitter<Object> = new EventEmitter<Object>();

  // seen_a_doctorStatus: string = '' 

  constructor(private apiService: ApiService, private modal: ModalComponent, private activatedRoute: ActivatedRoute) { 
    this.createIntakeForm = new FormGroup({
      enquiry_no: new FormControl(null),
      date: new FormControl(null),
      time: new FormControl(null),
      enquiry_type: new FormControl(null),
      call_received_by: new FormControl(null),
      assigned_to: new FormControl(null),
      caller_salutation: new FormControl(null, [Validators.minLength(0), Validators.maxLength(12)]),
      caller_name: new FormControl(null, Validators.pattern(/^[a-zA-Z ]*$/)),
      caller_telephone: new FormControl(null),
      caller_email_address: new FormControl(null, Validators.email),
      caller_home_address: new FormControl(null),
      client_salutation: new FormControl(null),
      client_name: new FormControl(null, Validators.pattern(/^[a-zA-Z ]*$/)),
      client_sex: new FormControl(null),
      client_age: new FormControl(null),
      client_telephone: new FormControl(null),
      client_home_address: new FormControl(null),
      relationship_with_caller: new FormControl(null),
      current_location: new FormControl(null),
      preferred_language: new FormControl(null),
      source_of_referral: new FormControl(null),
      complaint: new FormControl(null),
      seen_a_doctor: new FormControl(null),
      height: new FormControl(null),
      weight: new FormControl(null),
      feeding: new FormControl(null),
      bathing: new FormControl(null),
      toileting: new FormControl(null),
      grooming: new FormControl(null),
      ambulation: new FormControl(null),
      transfer: new FormControl(null),
      bed_mobility: new FormControl(null),
      problem: new FormControl(null),
      recomendation_service: new FormControl(null),
      services_referred: new FormControl(null),
      regen_rehab_criteria: new FormControl(null),
      token: new FormControl(null)
    });

    this.agentToken = this.activatedRoute.snapshot.queryParamMap.get(
      "token"
    );
    this.assetId = this.activatedRoute.snapshot.queryParamMap.get(
      "asset"
    );
    this.intakeId = this.activatedRoute.snapshot.queryParamMap.get(
      "intakeId"
    );
    console.warn("ASSE", this.intakeId);
    
  }

  ngOnInit(): void {
    this.getFormDetails()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formData']['currentValue']) {
    }
  }

  saveForm(): void {
      
      console.warn("CFCF", this.createIntakeForm);
      this.createIntakeForm.get('token').setValue(this.agentToken)
      if (this.createIntakeForm.valid) {
        // this.createIntakeForm.value['timezone'] = this.createIntakeForm.value['timezone']['value']
        const formValue = this.createIntakeForm.value;
        formValue['asset'] = this.assetId;
        // console.warn("CFCF", formValue);
        this.apiService.post('create-intake-form', formValue).subscribe(
          response => {
            console.log("RES::",response);
            if (response.status) {
              console.log(response.message);
              this.messageModal.successDialog(response.message);
            } else {
              this.messageModal.errorDialog(response.message);
            }
          },
          error => {
            console.log(error);
            this.onResult.emit({ close: true, message: error.messsage });
          }
        );
      } else {
        this.messageModal.errorDialog('Please fill the required fields');
      }
  }

  getFormDetails() {
    let data = {
      token: this.agentToken,
    }
    if (this.intakeId) data['intakeId'] = this.intakeId;
    this.apiService.post('validate-and-get-intake-form', data).subscribe(
      response => {
        console.log("RES::",response);
        if (response.status) {
          console.log(response.message);  
          let data = response['lastRecord']
          this.createIntakeForm.patchValue(data)
          this.ticketDetails = response['ticket'];
          this.createIntakeForm.get('enquiry_no').setValue(response['ticket']['ticketId'])
          // this.messageModal.successDialog(response.message);
        } else {
          this.messageModal.errorDialog(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  clearForm() {
    this.createIntakeForm.reset()
  }

  getCustomerData() {
    if (this.ticketDetails) {
      this.createIntakeForm.get('caller_telephone').setValue(this.ticketDetails['phoneNumber'])
    this.createIntakeForm.get('caller_name').setValue(this.ticketDetails['customerName'])
    this.createIntakeForm.get('caller_email_address').setValue(this.ticketDetails['customerEmail'])
    this.createIntakeForm.get('enquiry_type').setValue(this.ticketDetails['service_type'] && this.ticketDetails['service_type']['name'])
    this.createIntakeForm.get('caller_home_address').setValue(this.ticketDetails['customerAddress'])
    this.createIntakeForm.get('call_received_by').setValue(this.ticketDetails['createdBy'] && this.ticketDetails['createdBy']['fullname'])
    this.createIntakeForm.get('assigned_to').setValue(this.ticketDetails['assignedTo'])
    if (this.ticketDetails['createdAt']) {
      let date = new Date(parseFloat(this.ticketDetails['createdAt']))
      let fDate = this.formatdate(date)
      this.createIntakeForm.get('date').setValue(fDate)
      let fTime = `${date.getHours()}:${(date.getMinutes()<10?'0':'') + date.getMinutes()}`
      this.createIntakeForm.get('time').setValue(fTime)
    }
    }
  }

  formatdate(data) {
    if (typeof (data) === 'object') {
      var today = data;
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }
      today = yyyy + '-' + mm + '-' + dd;
      return today;
    }
    return;
  }

}
