import { Component, OnInit ,ViewChild,ElementRef} from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ApiService } from '../../../services/api.service';
import { environment } from '../../../../environments/environment';
import { ModalComponent } from '../../shared/modal/modal.component';
import { Socket } from 'ngx-socket-io';
import { socketService } from '../../../services/socket.service';


@Component({
  selector: 'app-update-timeline',
  templateUrl: './update-timeline.component.html',
  styleUrls: ['./update-timeline.component.css']
})
export class UpdateTimelineComponent implements OnInit {

  @ViewChild('resolveFile') resolveFile: ElementRef;
  @ViewChild("messageModal") messageModal: ModalComponent;
  activeRoute$: Subscription;
  timelineSocket$ : Subscription;
  fileUpload$: Subscription;
  updateTimeSave$: Subscription;
  getLis$: Subscription;
  validateToken$ : Subscription;
  submitComment$ : Subscription;

  showProgress: boolean = false;

  user_name: string = '';
  tokenUser : string = ''
  ticketcurrentStatus: any;
  recordList = [];

  maxChars: number = 300;

  updateTimeForm: FormGroup;
  uploadedObject = {};
  token : any

  constructor( private activatedRoute: ActivatedRoute,private socket: Socket, private apiService: ApiService,private socketService:socketService) { 
    this.updateTimeForm = new FormGroup({
      user_email: new FormControl('', [Validators.required]),
      user_id: new FormControl('', [Validators.required]),
      ticketId: new FormControl('', [Validators.required]),
      comments: new FormControl('', [Validators.required]),
      attachment: new FormControl(null, [])
    });
    this.activatedRoute.queryParams.subscribe( params => {
      this.token = params.token
    });
    console.log(this.token)
  }

  async ngOnInit() {
    // await this.getList();
    this.getTokenDetails()
  }

  openAttachment(file) {
    console.log(file)
    window.open(`${environment.api_img_url}${file}`)
  }

  /***********Service */

  onFileChange(fileInput) {

    const file = fileInput.target.files[0];
    console.log(file)
    const formData = new FormData();
    formData.append('file', file);

    this.apiService.post('upload-file', formData).subscribe(res => {
      console.log(res)
      if (!res.status) return;

      this.uploadedObject = { 'id': res.files.id, 'file': res.files[0], 'fileName': file.name };

    });
  }

  submitComment(){
    let data = {
      comment : this.updateTimeForm.value.comments,
      token : this.token,
      attachment : (this.uploadedObject['id'])?this.uploadedObject['id']:null,
    }
    if(!data.comment){
      this.messageModal.warnDialog('Please add your comments before submitting');
      return
    }
    this.submitComment$ = this.apiService.post('update-customer-timeline',data).subscribe((e)=>{
      if(e.status){
        this.uploadedObject = {}
        this.updateTimeForm.reset()
        this.resolveFile.nativeElement.value = "";

        this.getTokenDetails()
      }else{
        this.messageModal.errorDialog(e.message);
      }
    })
  }

  getTokenDetails(){
    let data ={
      token : this.token
    }
    this.validateToken$ = this.apiService.post('validate-ticket-and-get-timeline',data).subscribe((e)=>{
      console.log("TimelineData",e)
      if(e.status){
        this.recordList = e.data
        this.user_name = e.customerInfo.customerName;
        this.ticketcurrentStatus = this.recordList[0].ticket.currentStatus.status.status != 'Closed'?true:false;
        this.socket.ioSocket.io.disconnect()
        this.socket.ioSocket.io.opts.query = {user_token : this.token,userType : 'Customer'}
        this.socket.ioSocket.io.connect()
        this.initiateSocket(e['ticket']['ticketId'])
        this.updateTimeForm.get('ticketId').setValue(e.ticket['ticketId'])
        console.log(`${e.ticket['ticketId']}_LISTEN_TIMELINE_EMAIL`)
      }else{
        return false
      }
    })
  }

  //Initiate Socket
  initiateSocket(ticketId){
    this.timelineSocket$ =this.socketService.getMessage(`${ticketId}_LISTEN_TIMELINE_EMAIL`)
      .subscribe(msg => {
        this.recordList = msg.value
      });
  }


  submit() {

    console.log(this.updateTimeForm.value)


    if (this.updateTimeForm.invalid) return;


    this.showProgress = true;
    // this.apiService._post('api/appointment/customer-timeline-update', this.updateTimeForm.value).subscribe(res => {
    //   this.showProgress = false;
    //   console.log(res)
    //   if (!res.success) return this.modalService.errorMessage(res['message']).pipe(take(1));

    //   this.updateTimeForm.reset();
    //   this.updateTimeForm.updateValueAndValidity();
    //   this.updateFormFieldFromRoute();

    //   this.getList();
    //   this.modalService.successMessage(res['message']).pipe(take(1));



    // });
  }

  // async getList() {
  //   this.recordList = []
  // }
  ngOnDestroy(): void {
    if (this.validateToken$) this.validateToken$.unsubscribe();
    if (this.submitComment$) this.submitComment$.unsubscribe();

  }

}
